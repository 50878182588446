/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  testData: {},
  testId: '',
  htmlResponse: '',
  category: '',
  subCategory: '',
}

const logisticsDataSlice: any = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    updateTestData: (state, action) => {
      state.testData = action.payload
    },
    updateTestId: (state, action) => {
      state.testId = action.payload
    },

    updateSubCategory: (state, action) => {
      state.subCategory = action.payload
    },
    updateLogisticsReport: (state, action) => {
      return { ...state, htmlResponse: action.payload }
    },
    updateData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },

    resetLogisticsData: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateTestData,
  updateTestId,
  updateData,
  resetLogisticsData,
  updateLogisticsReport,
  updateCategory,
  updateSubCategory,
} = logisticsDataSlice.actions

export default logisticsDataSlice.reducer
