/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query'
import { instance } from 'services/axiosInstance'

const useGet = (key: string, url: string, configs?: any) => {
  const get = async () => {
    const { data } = await instance.get(url)
    return data
  }
  const defaultConfig = {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...configs,
  }
  return useQuery(key, get, defaultConfig)
}

export default useGet
