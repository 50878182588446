import Facebook from 'assets/svg/facebook'
import Twitter from 'assets/svg/twitter'
import Instagram from 'assets/svg/instagram'
import LinkedIn from 'assets/svg/linkedin'
import {
  FooterContent,
  FooterContentLink,
  FooterContentWrapper,
  FooterWrapper,
  Icon,
  IconContainer,
} from 'styles/components/Footer'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  const handleLinkClick = (event: any) => {
    event.preventDefault()
    window.open(event.target.href, '_blank')
  }
  const link =
    'https://ondc.org/privacy-policy/#:~:text=Personal%20information%20will%20only%20be,parties%20for%20their%20marketing%20purposes'

  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <FooterContentLink href={link} onClick={(event) => handleLinkClick(event)}>
          Privacy Policy
        </FooterContentLink>{' '}
        |{' '}
        <FooterContent
          onClick={() => {
            navigate('/alpha/terms-&-conditions')
          }}
        >
          Terms and Conditions
        </FooterContent>
      </FooterContentWrapper>
      <FooterContent>ONDC | Open Network for Digital Commerce © 2024</FooterContent>
      <IconContainer>
        <Icon>
          <Facebook />
        </Icon>
        <Icon>
          <Twitter />
        </Icon>
        <Icon>
          <Instagram />
        </Icon>
        <Icon>
          <LinkedIn />
        </Icon>
      </IconContainer>
    </FooterWrapper>
  )
}

export default Footer
