/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { boolean } from 'yup'

const initialState = {
  testData: {},
  testId: '',
  flowName: '',
  flowId: '',
  htmlResponse: '',
  buyerDelivery: boolean,
}

const buyerOnNetworkDataSlice: any = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    updateOnNetworkTestData: (state, action) => {
      state.testData = action.payload
    },
    updateOnNetworkTestId: (state, action) => {
      state.testId = action.payload
    },
    updateOnNetworkBuyerDelivery: (state, action) => {
      state.buyerDelivery = action.payload
    },
    updateOnNetworkFlowName: (state, action) => {
      state.flowName = action.payload
    },
    updateOnNetworkFlowId: (state, action) => {
      state.flowId = action.payload
    },

    updateOnNetworkHtmlResponse: (state, action) => {
      state.htmlResponse = action.payload
    },
    updateOnNetworkBuyerData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetOnNetworkBuyerData: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateOnNetworkTestData,
  updateOnNetworkTestId,
  updateOnNetworkBuyerData,
  resetOnNetworkBuyerData,
  updateOnNetworkFlowName,
  updateOnNetworkFlowId,
  updateOnNetworkHtmlResponse,
} = buyerOnNetworkDataSlice.actions

export default buyerOnNetworkDataSlice.reducer
