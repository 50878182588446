import { useMutation } from 'react-query'
import { instance, mockServerInstance } from 'services/axiosInstance'
import { IParams } from 'interface'

const post = async ({ url, payload, mockServer }: IParams) => {
  const { data } = mockServer ? await mockServerInstance.post(url, payload) : await instance.post(url, payload)
  return data
}

const usePost = () => useMutation(post)

export default usePost
