import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="#838383"
      d="M8.013 29.012a1.416 1.416 0 0 1-2.346-1.042v-3.178a2.833 2.833 0 0 1-2.833-2.833v-9.917a2.833 2.833 0 0 1 2.833-2.833H21.25a2.833 2.833 0 0 1 2.834 2.833v9.917a2.833 2.833 0 0 1-2.834 2.833h-7.079l-6.158 4.22ZM25.5 17.732c-.004.175-.004.35 0 .524v-.523Zm0 0c.007-.15.009-2.05.006-5.693a4.25 4.25 0 0 0-4.25-4.246H9.916V6.375a2.833 2.833 0 0 1 2.834-2.833h15.584a2.834 2.834 0 0 1 2.833 2.833v9.917a2.834 2.834 0 0 1-2.834 2.833v3.178a1.416 1.416 0 0 1-2.345 1.043l-.488-.335v-5.278Z"
    />
  </svg>
)
export default SvgComponent
