import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { buyerProgressFlows } from 'constants/constants'

interface OrderItem {
  status: string
  api: string
}

interface BuyerFlowItem {
  flow_name: string
  order: OrderItem[]
  flowStatus: string
  fileGenerated: boolean
}

const buyerFlowSlice: any = createSlice({
  name: 'buyerFlow',
  initialState: buyerProgressFlows as BuyerFlowItem[],
  reducers: {
    updateFileGenerated: (state, action: PayloadAction<{ flowName: string; fileGenerated: boolean }>) => {
      const { flowName } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item) {
        item.fileGenerated = true
      }
    },

    updateOrderStatus: (
      state,
      action: PayloadAction<{ flowName: string; api: string; status: string; index: number }>,
    ) => {
      const { flowName, api, status, index } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item) {
        item.flowStatus = 'started'
        item.order.forEach((orderItem, ind) => {
          if ((index == 0 || index == 1 || index == ind) && orderItem.api == api) {
            orderItem.status = status
          }
        })
      }
    },

    updateFlowStatus: (state, action: PayloadAction<{ flowName: string; message: string }>) => {
      const { flowName, message } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item && message === 'file generated successfully') {
        item.flowStatus = 'completed'
      }
    },
    // eslint-disable-next-line no-unused-vars
    setBuyerFlow: (state, action: PayloadAction<BuyerFlowItem[]>) => {
      return action.payload
    },
    // eslint-disable-next-line no-unused-vars
    resetBuyerFlow: (state, action) => {
      return action.payload
    },
  },
})

export const { updateFileGenerated, updateOrderStatus, updateFlowStatus, setBuyerFlow, resetBuyerFlow } =
  buyerFlowSlice.actions

export default buyerFlowSlice.reducer
