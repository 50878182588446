import { Steps, ConfigProvider } from 'antd'
import { useNavigate } from 'react-router-dom'

interface IStepper {
  current?: number
  next?: boolean
  navi?: string
}
const Stepper = ({ current, next, navi }: IStepper) => {
  const navigate = useNavigate()

  const onChange = (value: number) => {
    if (next) {
      switch (value) {
        case 0:
          navigate(`${navi}`)
          break
        case 1:
          navigate(`${navi}`)
          break
        case 2:
          navigate(`${navi}`)
          break
        default:
          break
      }
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#21ade8',
          borderRadius: 2,
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Steps
        current={current}
        direction="vertical"
        onChange={onChange}
        items={[
          {
            title: 'Select Module',
          },
          {
            title: 'Fill Application Details',
          },
          {
            title: 'Execute Testing & Review Results',
          },
        ]}
      />
    </ConfigProvider>
  )
}

export default Stepper
