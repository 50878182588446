/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  environment: '',
}

const homeDataSlice: any = createSlice({
  name: 'environment',
  initialState,

  reducers: {
    updateEnvironment: (state, action) => {
      state.environment = action.payload
    },
  },
})

export const { updateEnvironment } = homeDataSlice.actions

export default homeDataSlice.reducer
