import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#838383"
      d="M12.5 1c-6.075 0-11 4.925-11 11 0 6.074 4.925 11 11 11s11-4.926 11-11c0-6.075-4.925-11-11-11ZM7.935 5.961c.749 0 1.356.554 1.356 1.236 0 .683-.607 1.237-1.356 1.237-.748 0-1.355-.554-1.355-1.237 0-.682.607-1.236 1.355-1.236Zm1.366 10.574H6.623V9.548h2.678v6.987Zm9.098 0h-2.69v-3.842c0-.439-.082-.749-.244-.931-.162-.182-.39-.273-.684-.273a.973.973 0 0 0-.79.368c-.201.246-.302.687-.302 1.323v3.355h-2.678V9.548h2.494v1.138c.372-.465.75-.797 1.131-.997.382-.199.846-.299 1.395-.299.741 0 1.321.22 1.74.661.419.441.628 1.122.628 2.043v4.441Z"
    />
  </svg>
)
export default SvgComponent
