import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #f9f9fa;
  height: 100vh;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100% - 150px);
`
export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
`
export const NameWrapper = styled.div`
  width: 289px;
  background: linear-gradient(92deg, #1c75bc 6.62%, #00aeef 51.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px;
`
export const Name = styled.h3`
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  padding: 20px;
  width: 300px;
  height: 67vh;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: var(--Basic-white, #fff);
  gap: 10px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 55vh;
  }
`

export const RightWrapper = styled.div`
  gap: 4px;
`

export const HeadingWrapper = styled.div`
  height: 44px;
  margin: 5px;
`
export const Heading = styled.p`
  color: #606161;

  font-size: 26px;

  font-weight: 600;
  line-height: 40px;
`

export const RightContainer = styled.div`
  width: 69vw;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: #fff;
  height: 67vh;
  padding: 20px;
  @media (max-width: 1200px) {
    width: 62vw;
    height: 55vh;
  }
`
export const RightSection = styled.div``

export const StepsWrapper = styled.div``

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const InstructionsWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 45vh;
  @media (max-width: 1200px) {
    height: 40vh;
  }
`
export const RightHeading = styled.p`
  color: #606161;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`
export const Instruction = styled.p`
  color: var(--Black, #606161);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`
export const Instructions = styled.div`
  display: flex;
  align-items: baseline;
  padding: 5px;
  gap: 10px;
`
export const Bullet = styled.div`
  height: 5px;
  width: 5px;
  background-color: #606161;
  border-radius: 50%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  button {
    width: 350px;
    height: 50px;
  }
  @media (max-width: 1200px) {
    button {
      width: 250px;
      height: 40px;
    }
  }
`

export const FirstInstructionWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 5px;
  gap: 10px;
`
export const FirstInstruction = styled.p`
  color: rgb(33, 173, 232);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`
export const FeedbackWrapper = styled.div``
