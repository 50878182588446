import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import { SelectModalProps } from 'interface'
import { LoaderContext } from 'context/loader'
import Loader from 'components/Loader'
import {
  ButtonWrapper,
  Container,
  Heading,
  ModalBody,
  Text,
  TextContainer,
  TextWrapper,
} from 'styles/components/SelectModal'

const LogisticsBuyerSelect = ({
  isModalOpen,
  setIsModalOpen,
  heading,
  setTimer,
  callback,
  flowName,
  service,
  setService,
}: // startApi,
SelectModalProps) => {
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  // const [customFlow, setCustomFlow] = useState(false)
  const [content, setContent] = useState(false)
  // const [flag, setFlag] = useState(false)
  const [itemList, setItemList] = useState<any>([])
  const [events, setEvents] = useState<any>(null)
  const logisticsBuyerDataState = useSelector((state: any) => state?.logisticsBuyerData)
  const Flowname: any = flowName

  useEffect(() => {
    setService(false)
  }, [])

  useEffect(() => {
    // if (flowName === 'Flow 6: Search, Incremental Catalog Search & Custom Menu') {
    //   setCustomFlow(true)
    // }

    if (isModalOpen) {
      const test_id = logisticsBuyerDataState?.testId
      const parsedData = logisticsBuyerDataState?.testData
      const bap_id = parsedData.bap_id

      const url = `${process.env.REACT_APP_BASE_URL}/ssebuyer/${test_id}?bap_id=${bap_id}`

      const eventSource: EventSource = new EventSource(url)
      setEvents(eventSource)
      eventSource.addEventListener('search_call', (event: any) => {
        const data = JSON.parse(event.data)
        setItemList((prev: any) => [...prev, data])
      })

      eventSource.addEventListener('stop_search', (event: any) => {
        const data = JSON.parse(event.data)
        setTimer(false)
        setIsModalOpen(false)
        eventSource.close()
        return data
      })

      return () => {
        events?.close()
      }
    } else {
      setItemList([])
    }
  }, [isModalOpen])

  const startTestApi = async (testId: string, flowName: string) => {
    const xml = 'LogisticsBuyerTest'

    const StartTestPayload = {
      test_id: testId,
      flow_name: flowName,
      xml_name: xml,
    }

    try {
      setContent(false)
      const timeout = setTimeout(() => {
        setContent(true)
        setLoader(false)
      }, 120000)

      const response = await mutateAsync({
        url: APIS.POST_START_TEST_BUYER,
        payload: StartTestPayload,
      })

      if (response) {
        setLoader(true)
        clearTimeout(timeout)
        setContent(false)
      }
    } catch (error) {
      return error
    }
  }

  const callSelect = async (item: any) => {
    setLoader(true)
    try {
      const parsedData = logisticsBuyerDataState?.testData
      setContent(false)
      const test_id = logisticsBuyerDataState?.testId
      const flow_id = logisticsBuyerDataState?.flowId

      const NpType = 'LOGISTICS_BUYER'

      const payload = {
        flow_id: flow_id,
        transactionId: item.transactionId,
        testId: test_id,
        bapId: parsedData.bap_id,
        np_type: NpType,
      }

      const response = await mutateAsync({
        url: APIS.SELECT,
        payload: payload,
        mockServer: true,
      })
      if (!service) {
        if (response) {
          startTestApi(logisticsBuyerDataState?.testId, Flowname)
        }

        setIsModalOpen(false)
        setLoader(false)
        events.close()
        setEvents(null)
        callback()

        setIsModalOpen(false)
      }

      setIsModalOpen(false)
      setLoader(false)
      events?.close()
      setEvents(null)
    } catch (error) {
      setLoader(false)
    }
  }

  return (
    <>
      {isModalOpen && itemList.length === 0 ? (
        <Loader isModalOpen={true} text={'Waiting for 3 minutes for search call'} />
      ) : (
        <Modal open={isModalOpen} onCancel={handleCancel} centered footer={null} width={'555px'} maskClosable={false}>
          <ModalBody>
            <Heading>{heading}</Heading>
            <Container>
              {itemList?.map((item: any, index: number) => (
                <TextWrapper key={index}>
                  <TextContainer>
                    <Text>Item : {item?.item}</Text>
                    <Text>Bap Id : {item.bapId}</Text>
                    <Text>Action : {item.action}</Text>
                    <Text>Transaction Id : {item.transactionId}</Text>
                    <Text> TimeStamp : {item.timestamp}</Text>
                  </TextContainer>
                  <ButtonWrapper>
                    <button onClick={() => callSelect(item)}>Select</button>
                  </ButtonWrapper>
                </TextWrapper>
              ))}
            </Container>
          </ModalBody>
        </Modal>
      )}

      <Loader isModalOpen={content} text={'Please wait for 15 - 20 minutes as this test is in queue'} />
    </>
  )
}

export default LogisticsBuyerSelect
