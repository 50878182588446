export const domainSelectBox = [
  { value: 'ONDC:RET10', label: 'ONDC:RET10' },
  { value: 'ONDC:RET11', label: 'ONDC:RET11' },
  { value: 'ONDC:RET12', label: 'ONDC:RET12' },
  { value: 'ONDC:RET13', label: 'ONDC:RET13' },
  { value: 'ONDC:RET14', label: 'ONDC:RET14' },
  { value: 'ONDC:RET15', label: 'ONDC:RET15' },
  { value: 'ONDC:RET16', label: 'ONDC:RET16' },
  { value: 'ONDC:RET17', label: 'ONDC:RET17' },
  { value: 'ONDC:RET18', label: 'ONDC:RET18' },
  { value: 'ONDC:RET19', label: 'ONDC:RET19' },
]
export const logisticsBuyerDomainselect = [{ value: 'nic2004:60232', label: 'Logistics Buyer' }]
export const logisticsDomainSelectBox = [{ value: 'nic2004:60232', label: 'Logistics' }]
export const npTypeSelectBox = [{ value: 'SELLER', label: 'Seller-Np' }]
export const npTypeSelectBoxSeller = [{ value: 'SELLER', label: 'Seller-Np' }]

export const npTypeBuyerSelectBox = [{ value: 'BUYER', label: 'Buyer-Np' }]
export const npTypeLogisticsBuyerSelectBox = [{ value: 'LOGISTICS_BUYER', label: 'Logistics-Buyer-Np' }]
export const npTypeLogisticsSelectBox = [{ value: 'LOGISTICS_SELLER', label: 'Logistics-Seller-Np' }]

export const categoryLogisticsSelectBox = [
  { value: 'standard-delivery', label: 'Standard Delivery' },
  { value: 'express-delivery', label: 'Express delivery' },
  { value: 'immediate-delivery', label: 'Immediate Delivery' },
  { value: 'same-day', label: 'Same Day Delivery' },
  { value: 'next-day', label: 'Next Day Delivery' },
]

export const SelectHits = [
  { value: 'Simultaneously', label: 'Simultaneously' },
  { value: 'Non-Simultaneously', label: 'Non-Simultaneously' },
]
export const versionSelectBox = [{ value: '1.2.0', label: '1.2.0' }]

export const domainSelection: any = [
  { value: 'Retail', label: 'Retail' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Credit', label: 'Credit' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Ride Hailing', label: 'Ride Hailing' },
  { value: 'Unreserved Ticket Booking', label: 'Unreserved Ticket Booking' },
  { value: 'Reserved Ticket Booking', label: 'Reserved Ticket Booking' },
  { value: 'Investments', label: 'Investments' },
]

export const transactionSelection = [{ value: 'B2C', label: 'B2C' }]

export const environmentSelection = [
  { value: 'staging', label: 'Staging' },
  { value: 'pre-prod', label: 'Pre-prod' },
]

export const roleSelection: any = {
  Retail: [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  Logistics: [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  Credit: [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  Insurance: [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  'Unreserved Ticket Booking': [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  'Ride Hailing': [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  'Reserved Ticket Booking': [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
  Investments: [
    { value: 'Buyer-Np', label: 'Buyer-Np' },
    { value: 'Seller-Np', label: 'Seller-Np' },
  ],
}

export const homeVersionSelectBox: any = {
  Retail: [{ value: '1.2.0', label: 'v1.2.0' }],
  Logistics: [{ value: '1.2.0', label: 'v1.2.0' }],
  Credit: [
    { value: 'v2.0.0 ', label: 'v2.0.0 ' },
    { value: 'v2.1.0', label: 'v2.1.0' },
  ],
  Insurance: [{ value: 'v2.0.0 ', label: 'v2.0.0 ' }],
  'Unreserved Ticket Booking': [{ value: 'v2.0.0 ', label: 'v2.0.0 ' }],
  'Ride Hailing': [{ value: 'v2.0.1 ', label: 'v2.0.1' }],
  'Reserved Ticket Booking': [{ value: 'v2.0.0 ', label: 'v2.0.0 ' }],
  Investments: [{ value: 'v2.0.0 ', label: 'v2.0.0 ' }],
}
