import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import usePost from 'hooks/usePost'
import { SellerOffSelectModalProps } from 'interface'
import APIS from 'constants/api'
import { LoaderContext } from 'context/loader'
import Loader from 'components/Loader'
import {
  ButtonWrapper,
  Container,
  Heading,
  ModalBody,
  Text,
  TextContainer,
  TextWrapper,
} from 'styles/components/SelectModal'

const SellerOffNetworkSelectModal = ({
  isModalOpen,
  setIsModalOpen,
  heading,
  flowName,
  callback,
}: SellerOffSelectModalProps) => {
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const [itemList, setItemList] = useState<any>([])
  const [events, setEvents] = useState<any>(null)
  const sellerOffNetworkDataState = useSelector((state: any) => state?.sellerData)

  useEffect(() => {
    if (isModalOpen) {
      const test_id = sellerOffNetworkDataState?.testId
      const parsedData = sellerOffNetworkDataState?.testData

      const url = `${process.env.REACT_APP_BASE_URL}/ssebuyer/${test_id}?bap_id=${parsedData.bpp_id}`

      const eventSource: EventSource = new EventSource(url)
      setEvents(eventSource)
      eventSource.addEventListener('search_call', (event: any) => {
        const data = JSON.parse(event.data)
        setItemList((prev: any) => [...prev, data])
      })

      eventSource.addEventListener('stop_search', (event: any) => {
        const data = JSON.parse(event.data)
        setIsModalOpen(false)
        eventSource.close()
        return data
      })

      return () => {
        events?.close()
      }
    } else {
      setItemList([])
    }
  }, [isModalOpen])

  const callSelect = async (item: any) => {
    try {
      const parsedData = sellerOffNetworkDataState?.testData
      const test_id = sellerOffNetworkDataState?.testId
      const payload = {
        transactionId: item.transactionId,
        testId: test_id,
        bapId: parsedData.bpp_id,
        np_type: 'retail',
      }

      const startTestApi = async (testId: string) => {
        const StartTestPayload = {
          test_id: testId,
          flow_name: flowName,
          xml_name: 'ApiFlowTest',
        }
        await mutateAsync({
          url: APIS.POST_START_TEST,
          payload: StartTestPayload,
        })
      }

      await mutateAsync({
        url: APIS.SELECT,
        payload: payload,
        mockServer: true,
      })
      callback()
      startTestApi(sellerOffNetworkDataState?.testId)
      setIsModalOpen(false)

      events.close()
      setEvents(null)
      setIsModalOpen(false)
    } catch (error) {
      setLoader(false)
    }
  }

  return (
    <>
      {isModalOpen && itemList.length === 0 ? (
        <Loader isModalOpen={true} text={'Waiting for 3 minutes for info call '} />
      ) : (
        <Modal open={isModalOpen} onCancel={handleCancel} centered footer={null} width={'555px'} maskClosable={false}>
          <ModalBody>
            <Heading>{heading}</Heading>
            <Container>
              {itemList?.map((item: any, index: number) => {
                return (
                  <TextWrapper key={index}>
                    <TextContainer>
                      <Text>Bpp Id : {item.bppId}</Text>
                      <Text>Action : {item.action}</Text>
                      <Text>Transaction Id : {item.transactionId}</Text>
                      <Text> TimeStamp : {item.timestamp}</Text>
                    </TextContainer>
                    <ButtonWrapper>
                      <button onClick={() => callSelect(item)}>Select</button>
                    </ButtonWrapper>
                  </TextWrapper>
                )
              })}
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default SellerOffNetworkSelectModal
