import { Steps, ConfigProvider } from 'antd'
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'

const Track = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#21ade8',
          borderRadius: 2,
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Steps
        items={[
          {
            title: 'Order Placed',
            status: 'finish',
            icon: <UserOutlined />,
          },
          {
            title: 'In Transit',
            status: 'finish',
            icon: <SolutionOutlined />,
          },
          {
            title: 'Out Of Delivery',
            status: 'process',
            icon: <LoadingOutlined />,
          },
          {
            title: 'Delivered',
            status: 'wait',
            icon: <SmileOutlined />,
          },
        ]}
      />
    </ConfigProvider>
  )
}

export default Track
