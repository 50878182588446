import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sellerProgressFlowsOnNetwork } from 'constants/constants'

interface ISellerFlowItem {
  flow_name: string
  order: OrderItem[]
  flowStatus: string
  fileGenerated: boolean
  logisticsOrder: string[]
}

interface OrderItem {
  status: string
  api: string
}

const sellerOnNetworkFlowSlice: any = createSlice({
  name: 'sellerFlow',
  initialState: sellerProgressFlowsOnNetwork as ISellerFlowItem[],
  reducers: {
    updateSellerOnNetworkFlowFileGenerated: (
      state,
      action: PayloadAction<{ flowName: string; fileGenerated: boolean }>,
    ) => {
      const { flowName } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item) {
        item.fileGenerated = true
      }
    },
    updateSellerOnNetworkOrderStatus: (state, action: PayloadAction<{ flowName: string; status: string }>) => {
      const { flowName, status } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item) {
        item.flowStatus = 'started'
        const orderItem = item.order.find((orderItem) => orderItem.status === status)
        if (orderItem) {
          orderItem.status = status
        }
      }
    },
    updateSellerOnNetworkFlowStatus: (state, action: PayloadAction<{ flowName: string; message: string }>) => {
      const { flowName, message } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item && message === 'file generated successfully') {
        item.flowStatus = 'completed'
      }
    },

    // eslint-disable-next-line no-unused-vars
    setSellerOnNetworkFlow: (state, action: PayloadAction<ISellerFlowItem[]>) => {
      return action.payload
    },
    // eslint-disable-next-line no-unused-vars
    resetSellerOnNetworkFlow: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateSellerOnNetworkFlowFileGenerated,
  updateSellerOnNetworkOrderStatus,
  updateSellerOnNetworkFlowStatus,
  setSellerOnNetworkFlow,
  resetSellerOnNetworkFlow,
} = sellerOnNetworkFlowSlice.actions

export default sellerOnNetworkFlowSlice.reducer
