import { Button, Modal } from 'antd'
import { CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import { ILoader } from 'interface'
import {
  ButtonWrapper,
  LoaderContainer,
  ModalWraper,
  SubText,
  SubTextContainer,
  SubTextWrapper,
  Text,
  TextContainer,
  TextWrapper,
} from 'styles/components/Loader'

const Loader = ({
  isModalOpen,
  text,
  subText,
  transactionBtn,
  transactionBtnFn,
  issueBtn,
  issueBtnFn,
  subText1,
  subText2,
}: ILoader) => {
  return (
    <>
      <Modal
        centered
        open={isModalOpen}
        closeIcon={null}
        footer={null}
        width={'380'}
        styles={{ body: { height: '360px' } }}
      >
        <ModalWraper>
          <LoaderContainer>
            <LoadingOutlined style={{ fontSize: '120px', color: '#21ADE8', margin: '20px' }} />
          </LoaderContainer>
          <TextContainer>{text}</TextContainer>

          <SubTextWrapper>
            <TextWrapper>
              <SubText> {subText}</SubText>
            </TextWrapper>

            {transactionBtn && (
              <SubTextContainer>
                <Text> {subText1}</Text>
                {
                  <ButtonWrapper>
                    <Button
                      type="text"
                      icon={<CopyOutlined style={{ color: ' #f29d4a' }} />}
                      size={'middle'}
                      onClick={transactionBtnFn}
                    />
                  </ButtonWrapper>
                }
              </SubTextContainer>
            )}

            {issueBtn && (
              <SubTextContainer>
                <Text> {subText2}</Text>
                {
                  <ButtonWrapper>
                    <Button
                      type="text"
                      icon={<CopyOutlined style={{ color: ' #f29d4a' }} />}
                      size={'middle'}
                      onClick={issueBtnFn}
                    />
                  </ButtonWrapper>
                }
              </SubTextContainer>
            )}
          </SubTextWrapper>
        </ModalWraper>
      </Modal>
    </>
  )
}

export default Loader
