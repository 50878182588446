import styled from 'styled-components'

export const FormWrapper = styled.div`
  margin: 12px 0px;
  /* height: 66px; */
  padding: 0 5px;
  label {
    font-size: 14px;
    font-weight: 600;
  }
`

export const Label = styled.label``
export const FormItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px;
  label {
    font-size: 14px;
    font-weight: 600;
  }
`

export const FormInnerContainer = styled.div`
  width: 100%;
  height: 468px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background: #d9d9d9;
  }
`
export const HeaderWrapper = styled.div``
export const ValueWrapper = styled.div``

export const ErrorMessage = styled.div`
  color: #d83232;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 0;
  text-align: start;
`
export const ButtonWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  margin-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  button {
    width: 203px;
    height: 56px;
  }
`
export const ModalBody = styled.div`
  width: 524px;
  overflow-y: auto;
`
