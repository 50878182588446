import styled from 'styled-components'

export const ModalWraper = styled.div`
  width: 380px;
  height: 350px;
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

export const LoaderContainer = styled.div``
export const TextContainer = styled.h4`
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`
export const SubTextWrapper = styled.div``
export const TextWrapper = styled.div``
export const SubTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const SubText = styled.h4`
  margin: 2px 0;
  font-size: 14px;
  color: #1b1b1b;
`
export const Text = styled.h3`
  font-size: 12px;
  color: #666666;
`
export const ButtonWrapper = styled.div``
