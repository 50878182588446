import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 34 34" {...props}>
    <path
      stroke="#838383"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.75 18.416h8.5m-8.5 5.667H17M5.666 5.666v22.667A2.833 2.833 0 0 0 8.5 31.166h17a2.833 2.833 0 0 0 2.833-2.833V11.818a2.834 2.834 0 0 0-.853-2.026L21.19 3.64a2.833 2.833 0 0 0-1.98-.808H8.5a2.833 2.833 0 0 0-2.833 2.833Z"
    />
    <path
      stroke="#838383"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.833 2.833V8.5a2.833 2.833 0 0 0 2.833 2.833h5.667"
    />
  </svg>
)
export default SvgComponent
