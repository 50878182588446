import styled from 'styled-components'

export const TrackWrapper = styled.div`
  width: 80%;
`
export const ContentWrapper = styled.div``
export const RightSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  button {
    width: 200px;
    height: 50px;
  }
  @media (max-width: 1200px) {
    button {
      width: 150px;
      height: 40px;
    }
  }
`
