import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { LoaderWrapper } from 'styles/components/loading'

const Loading: React.FC = () => {
  return (
    <LoaderWrapper>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
    </LoaderWrapper>
  )
}

export default Loading
