/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  testData: {},
  testId: '',
  flowName: '',
  flowId: '',
  htmlResponse: '',
}

const sellerOnNetworkDataSlice: any = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    updateSellerOnNetworkTestData: (state, action) => {
      state.testData = action.payload
    },
    updateSellerOnNetworkTestId: (state, action) => {
      state.testId = action.payload
    },
    updateSellerOnNetworkFlowName: (state, action) => {
      state.flowName = action.payload
    },

    updateSellerOnNetworkHtmlResponse: (state, action) => {
      state.htmlResponse = action.payload
    },
    updateSellerOnNetworkData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetSellerOnNetworkData: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateSellerOnNetworkTestData,
  updateSellerOnNetworkTestId,
  updateSellerOnNetworkFlowName,

  updateSellerOnNetworkData,
  resetSellerOnNetworkData,
  updateSellerOnNetworkHtmlResponse,
} = sellerOnNetworkDataSlice.actions

export default sellerOnNetworkDataSlice.reducer
