import { useMemo, useState } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import Loading from 'components/loading'
import { PayloadContext } from 'context'
import { LoaderContext } from 'context/loader'
import { routes } from 'routes/routes'
import { store } from 'store'
import {
  selectData,
  searchData,
  confirmData,
  initData,
  statusData,
  trackData,
  issueData,
  issueStatusData,
  cancelData,
  updateData,
  infoData,
  ratingData,
  catalog_errorData,
  catalog_rejection,
} from 'logs/apiData'
import './App.css'

const router = createBrowserRouter(routes)
const queryClient = new QueryClient()

const App = () => {
  const [payloadData, setPayloadData] = useState({
    search: searchData,
    select: selectData,
    init: initData,
    confirm: confirmData,
    status: statusData,
    track: trackData,
    issue: issueData,
    issue_status: issueStatusData,
    cancel: cancelData,
    update: updateData,
    info: infoData,
    rating: ratingData,
    catalog_error: catalog_errorData,
    catalog_rejection: catalog_rejection,
  })
  const [loader, setLoader] = useState(false)
  const persistor = persistStore(store)
  const loaderProp = useMemo(() => ({ loader, setLoader }), [])
  const payloadDataProp = useMemo(() => ({ payloadData, setPayloadData }), [])
  return (
    <QueryClientProvider client={queryClient}>
      {loader && <Loading />}

      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <LoaderContext.Provider value={loaderProp}>
            <PayloadContext.Provider value={payloadDataProp}>
              <RouterProvider router={router} />
            </PayloadContext.Provider>
          </LoaderContext.Provider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
