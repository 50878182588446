import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #f9f9fa;
  height: 100vh;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100% - 150px);
`
export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
`
export const NameWrapper = styled.div`
  width: 289px;
  background: linear-gradient(92deg, #1c75bc 6.62%, #00aeef 51.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px;
`
export const Name = styled.h3`
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  padding: 20px;
  width: 300px;
  height: 67vh;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: var(--Basic-white, #fff);
  gap: 10px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 55vh;
  }
  @media (max-height: 780px) {
    width: 280px;
    height: 62vh;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  height: 50vh;

  @media (max-width: 1200px) {
    width: 250px;
    height: 45vh;
  }
  @media (max-height: 780px) {
    width: 280px;
    /* height: 45vh; */
  }
`

export const RightWrapper = styled.div``

export const HeadingWrapper = styled.div`
  height: 44px;
  margin: 5px;
`
export const Heading = styled.p`
  color: #606161;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
`
export const FormHeading = styled.p`
  color: #606161;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
`
export const RightContainer = styled.div`
  width: 69vw;
  height: 67vh;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: #fff;
  padding: 20px;
  @media (max-width: 1200px) {
    width: 62vw;
    height: 55vh;
  }
  @media (max-height: 780px) {
    width: 67vw;
    height: 62vh;
  }
`
export const RightSection = styled.div`
  width: 100%;
`

export const Label = styled.h4`
  color: #afb0b0;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px;
`

export const StepsWrapper = styled.div``
export const WatermarkWrapper = styled.div``

export const FormWrapper = styled.div``

export const FormRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 18px;

  @media (max-width: 1200px) {
    padding: 10px;
  }
  @media (max-height: 780px) {
    padding: 10px;
  }
`
export const CaptchaWrapper = styled.div``
export const InputWrapper = styled.div``

export const ButtonWrapper = styled.div`
  /* padding: 10px 20px; */
  button {
    width: 350px;
    height: 50px;
  }
  @media (max-width: 1200px) {
    button {
      width: 250px;
      height: 40px;
    }
  }
  @media (max-height: 780px) {
    button {
      width: 250px;
      height: 40px;
    }
  }
`

export const FeedbackWrapper = styled.div``

export const ContentHead = styled.p`
  margin-bottom: 10px;
  color: rgb(96, 97, 97);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  @media (max-height: 780px) {
    font-size: 16px;
  }
`
export const Content = styled.p`
  margin-bottom: 10px;
  color: rgb(175, 176, 176);
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;

  @media (max-height: 780px) {
    font-size: 14px;
  }
`
