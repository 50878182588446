import { ILoaderContext } from 'interface'
import { createContext } from 'react'

const defaultState = {
  loader: false,
  setLoader: () => null,
}
const LoaderContext = createContext<ILoaderContext>(defaultState)

export { LoaderContext }
