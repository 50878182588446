/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  testData: {},
  testId: '',
  flowName: '',
  flowId: '',
  htmlResponse: '',
}

const logisticsBuyerDataSlice: any = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    updateTestData: (state, action) => {
      state.testData = action.payload
    },
    updateTestId: (state, action) => {
      state.testId = action.payload
    },

    updateLogisticsBuyerFlowName: (state, action) => {
      state.flowName = action.payload
    },
    updateLogisticsBuyerFlowId: (state, action) => {
      state.flowId = action.payload
    },

    updateLogisticsBuyerHtmlResponse: (state, action) => {
      state.htmlResponse = action.payload
    },
    updateLogisticsBuyerData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetLogisticsBuyerData: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateTestData,
  updateTestId,
  updateLogisticsBuyerData,
  resetLogisticsBuyerData,
  updateLogisticsBuyerFlowName,
  updateLogisticsBuyerFlowId,
  updateLogisticsBuyerHtmlResponse,
} = logisticsBuyerDataSlice.actions

export default logisticsBuyerDataSlice.reducer
