import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Navbar from 'components/Navbar'
import CustomButton from 'components/Button'
import LogisticsForm from 'components/LogisticsForm'
import Footer from 'components/Footer'
import Stepper from 'components/Stepper'
import InstructionList from 'components/InstructionList'
import Watermark from 'assets/svg/watermark'
import Info from 'assets/svg/info'
import { resetLogisticsData } from 'store/logisticsDataSlice'
import { resetLogisticsFlow } from 'store/logisticsFlowSlice'
import {
  Container,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightSection,
  RightWrapper,
  StepsWrapper,
  Wrapper,
  ButtonWrapper,
  InfoWrapper,
  RightHeading,
  FeedbackWrapper,
} from 'styles/pages/logisticsTesting'

const LogisticsTesting = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [next, setnext] = useState(false)
  const handleClick = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    dispatch(resetLogisticsData([]))
    dispatch(resetLogisticsFlow([]))
    window.localStorage.clear()
    setnext(true)
  }, [])

  return (
    <>
      <Wrapper>
        <Navbar />

        <Container>
          <LeftWrapper>
            <NameWrapper>
              <Name>Pramaan</Name>
            </NameWrapper>
            <LeftContainer>
              <StepsWrapper>
                <Stepper current={1} next={next} navi="/" />
              </StepsWrapper>
              <Watermark />
              <FeedbackWrapper>
                <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
              </FeedbackWrapper>
            </LeftContainer>
          </LeftWrapper>
          <RightWrapper>
            <HeadingWrapper>
              <Heading>Fill Application Details</Heading>
            </HeadingWrapper>
            <RightContainer>
              <RightSection>
                <InfoWrapper>
                  <Info />
                  <RightHeading>Read this before you proceed further</RightHeading>
                </InfoWrapper>
                <InstructionList />
                <ButtonWrapper>
                  <CustomButton label={'Fill Application Details'} variant="contained" onClick={handleClick} />
                </ButtonWrapper>
              </RightSection>
            </RightContainer>
          </RightWrapper>
        </Container>
        {modalOpen && (
          <LogisticsForm
            isModalOpen={modalOpen}
            setIsModalOpen={setModalOpen}
            cancelBtnLabel={'Cancel'}
            confirmBtnLabel="Proceed further"
          />
        )}
        <Footer />
      </Wrapper>
    </>
  )
}

export default LogisticsTesting
