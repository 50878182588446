import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#838383"
      d="M12 .5C5.925.5 1 5.455 1 11.567 1 17.091 5.023 21.67 10.281 22.5v-7.734H7.488v-3.199h2.793V9.13c0-2.774 1.643-4.306 4.155-4.306 1.204 0 2.462.216 2.462.216v2.724h-1.387c-1.366 0-1.792.853-1.792 1.728v2.076h3.05l-.487 3.2h-2.563V22.5C18.977 21.67 23 17.091 23 11.567 23 5.455 18.075.5 12 .5Z"
    />
  </svg>
)
export default SvgComponent
