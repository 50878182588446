import {
  useEffect,
  // useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
  // Checkbox,
  Input,
  Modal,
  Select,
} from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import usePost from '../../hooks/usePost'
import { FormModelProps, IBuyerPayload, IPayloadProps } from 'interface'
import { LOGISTICS_BUYER_TEST_VALIDATION_SCHEMA } from 'validations'
import APIS from 'constants/api'
import {
  // SelectHits,
  logisticsBuyerDomainselect,
  //   npTypeBuyerSelectBox,
  npTypeLogisticsBuyerSelectBox,
  //   npTypeSelectBox,
  versionSelectBox,
} from 'constants/select'
import { updateLogisticsBuyerData } from 'store/logisticsBuyerDataSlice'
import CustomButton from 'components/Button'
import Loader from 'components/Loader'
import {
  ButtonWrapper,
  ErrorMessage,
  FormInnerContainer,
  FormWrapper,
  Label,
  ModalBody,
} from 'styles/components/BuyerForm'
import { clearAllEvents } from 'store/logsSlice'

// import { updateOnNetworkBuyerData } from 'store/buyerOnNetworkDataSlice'

const LogisticsBuyerForm = ({
  isModalOpen,
  setIsModalOpen,
  cancelBtnLabel,
  confirmBtnLabel,
}: //   buyerFlag,
FormModelProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { mutateAsync, isLoading, isSuccess } = usePost()
  //   const [showNextSelect, setShowNextSelect] = useState(false)
  //   const [isChecked, setIsChecked] = useState(false)
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LOGISTICS_BUYER_TEST_VALIDATION_SCHEMA),
    defaultValues: {
      subscriber_uri: '',
      subscriber_id: '',
    },
  })

  const handleClearAllEvents = () => {
    dispatch(clearAllEvents())
  }

  const onSubmit = async (data: IPayloadProps) => {
    handleClearAllEvents()
    try {
      let metaData: { testData: any; testId: string; flowName: string; flowId: string } = {
        testData: {},
        testId: '',
        flowName: '',
        flowId: '',
      }
      const payload: IBuyerPayload = {
        domain: data.domain,
        np_type: data.np_type,
        version: data.version,
        bap_uri: data.subscriber_uri,
        bap_id: data.subscriber_id,
        session_id: window.localStorage.getItem('sessionId') || '1',
        uuid: uuidv4(),
        logistics_bap_uri: data.logistics_bap_uri,
        logistics_bap_id: data.logistics_bap_id,
        type_of_hits: data.type_of_hits,
        environment_type: environment,
      }
      metaData = { ...metaData, testData: payload }
      const response = await mutateAsync({
        url: APIS.POST_TEST,
        payload: payload,
      })
      metaData = { ...metaData, testId: String(response?.test_id) }
      dispatch(updateLogisticsBuyerData(metaData))
      navigate('/alpha/logistics-buyer-progress')
      //   dispatch(updateBuyerDelivery(showNextSelect))

      // if (showNextSelect || typeof showNextSelect === 'undefined') {
      //   dispatch(updateOnNetworkBuyerData(metaData))
      //   navigate('/alpha/buyer-flow-on-network-progress')
      // } else {
      //   dispatch(updateBuyerData(metaData))
      //   navigate('/alpha/buyer-flow')
      // }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (isSuccess) {
      reset()
      setIsModalOpen(false)
    }
  }, [isSuccess])

  const handleCancel = () => {
    setIsModalOpen(false)
    reset()
  }
  //   const handleCheckboxChange = (event: any) => {
  //     const show = event.target.checked
  //     if (show) {
  //       setIsChecked(true)
  //       setShowNextSelect(true)
  //     } else {
  //       setIsChecked(false)
  //       setShowNextSelect(false)
  //     }
  //   }

  return (
    <>
      {isLoading ? (
        <Loader isModalOpen={true} text={'Generating flows'} />
      ) : (
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          width={'572px'}
          styles={{ body: { height: '552px', color: '#606161' } }}
          title="Fill Application Details"
          footer={null}
          maskClosable={false}
        >
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInnerContainer>
                <FormWrapper>
                  <label>Domain *</label>
                  <Controller
                    name="domain"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Domain"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={logisticsBuyerDomainselect}
                      />
                    )}
                  />
                  {errors.domain && <ErrorMessage>{errors?.domain?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Version *</Label>
                  <Controller
                    name="version"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Version"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={versionSelectBox}
                      />
                    )}
                  />
                  {errors.version && <ErrorMessage>{errors?.version?.message}</ErrorMessage>}
                </FormWrapper>

                <FormWrapper>
                  <Label>NP Type *</Label>
                  <Controller
                    name="np_type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select NP_Type"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={npTypeLogisticsBuyerSelectBox}
                        // options={buyerFlag ? npTypeBuyerSelectBox : npTypeSelectBox}
                      />
                    )}
                  />

                  {errors.np_type && <ErrorMessage>{errors?.np_type?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Subscriber URI *</Label>
                  <Controller
                    name="subscriber_uri"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter URI" size="large" />}
                  />
                  {errors.subscriber_uri && <ErrorMessage>{errors?.subscriber_uri?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Subscriber Id *</Label>
                  <Controller
                    name="subscriber_id"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter Id" size="large" />}
                  />
                  {errors.subscriber_id && <ErrorMessage>{errors?.subscriber_id?.message}</ErrorMessage>}
                </FormWrapper>
                {/* <FormWrapper>
                  <Controller
                    name="buyerDelivery"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={isChecked} onChange={handleCheckboxChange}>
                        Buyer Delivery ?
                      </Checkbox>
                    )}
                  />
                  {errors.buyerDelivery && <ErrorMessage>{errors?.buyerDelivery?.message}</ErrorMessage>}
                </FormWrapper>
                {showNextSelect && (
                  <>
                    <FormWrapper>
                      <Label>Logistics Bap URI *</Label>
                      <Controller
                        name="logistics_bap_uri"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter URI" size="large" />}
                      />
                      {errors.logistics_bap_uri && <ErrorMessage>{errors?.logistics_bap_uri?.message}</ErrorMessage>}
                    </FormWrapper>
                    <FormWrapper>
                      <Label>Logistics Bap Id *</Label>
                      <Controller
                        name="logistics_bap_id"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter Id" size="large" />}
                      />
                      {errors.logistics_bap_id && <ErrorMessage>{errors?.logistics_bap_id?.message}</ErrorMessage>}
                    </FormWrapper>
                    <FormWrapper>
                      <Label>Type Of Hits *</Label>
                      <Controller
                        name="type_of_hits"
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Type of Hits"
                            size="large"
                            style={{ width: '100%' }}
                            {...field}
                            options={SelectHits}
                          />
                        )}
                      />
                      {errors.type_of_hits && <ErrorMessage>{errors?.type_of_hits?.message}</ErrorMessage>}
                    </FormWrapper>
                  </>
                )} */}
              </FormInnerContainer>
              <ButtonWrapper>
                <CustomButton label={cancelBtnLabel} variant="outline" onClick={handleCancel} />
                {confirmBtnLabel && <CustomButton label={confirmBtnLabel} variant="gradient" />}
              </ButtonWrapper>
            </form>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default LogisticsBuyerForm
