import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path fill="#838383" d="M11.5 22c6.075 0 11-4.925 11-11s-4.925-11-11-11S.5 4.925.5 11s4.925 11 11 11Z" />
    <path
      fill="#fff"
      d="M18.553 6.625a5.777 5.777 0 0 1-1.662.456 2.902 2.902 0 0 0 1.273-1.601 5.782 5.782 0 0 1-1.838.702 2.893 2.893 0 0 0-4.931 2.64A8.216 8.216 0 0 1 5.43 5.799a2.892 2.892 0 0 0 .895 3.863 2.88 2.88 0 0 1-1.31-.362v.037c0 1.402.997 2.57 2.32 2.837a2.88 2.88 0 0 1-1.306.05 2.897 2.897 0 0 0 2.703 2.01 5.806 5.806 0 0 1-4.285 1.198 8.19 8.19 0 0 0 4.436 1.3c5.323 0 8.233-4.409 8.233-8.233 0-.125-.002-.25-.008-.374a5.895 5.895 0 0 0 1.445-1.5Z"
    />
  </svg>
)
export default SvgComponent
