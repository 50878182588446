import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

const mockServerInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export { instance, mockServerInstance }
