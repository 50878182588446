import styled from 'styled-components'

export const NavbarWrapper = styled.div`
  background: #ffffff;
  height: 77px;
  display: flex;
`
export const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const LogoWrapper = styled.div`
  margin: 14px;
  width: 116.84px;
  height: 60px;
  cursor: pointer;
`
export const NavbarContent = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`
export const TooltipContainer = styled.div``
export const TextWrapper = styled.div``
export const HighlightedText = styled.p`
  color: #21ade8;
`

export const Text = styled.p`
  color: #1d1d1d;
  span {
    color: #21ade8;
  }
`
export const Link = styled.a`
  color: #21ade8;
  cursor: pointer;
`
