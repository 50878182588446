import Home from 'pages/home'
import SellerTesting from 'pages/sellerTesting'
import BuyerTesting from 'pages/buyerTesting'
import BuyerFlow from 'pages/buyerFlow'
import PageNotFound from 'components/404'
import LogisticsTesting from 'pages/logisticsTesting'
import LogisticsProgress from 'pages/logisticsProgress'
import SellerOnNetworkProgress from 'pages/sellerOnNetworkProgress'
import FlowProgress from 'pages/flowProgress'
import Feedback from 'pages/feedback'
import OrderTracking from 'pages/orderTracking'
import BuyerOnNetworkFlow from 'pages/buyerOnNetworkFlow'
import TermsConditions from 'pages/TermsConditions'
import LogisticsBuyerTesting from 'pages/logisticsBuyerTesting'
import LogisticsBuyerProgress from 'pages/logisticsBuyerProgress'

export const routes = [
  {
    path: '/',
    element: <Home />,
  },

  {
    path: 'alpha/seller-testing',
    element: <SellerTesting />,
  },

  {
    path: 'alpha/buyer-testing',
    element: <BuyerTesting />,
  },

  {
    path: 'alpha/logistics-testing',
    element: <LogisticsTesting />,
  },
  {
    path: 'alpha/logistics-buyer-testing',
    element: <LogisticsBuyerTesting />,
  },

  {
    path: 'alpha/buyer-flow',
    element: <BuyerFlow />,
  },
  {
    path: 'alpha/buyer-flow-on-network-progress',
    element: <BuyerOnNetworkFlow />,
  },

  {
    path: 'alpha/seller-off-network-progress',
    element: <FlowProgress />,
  },
  {
    path: 'alpha/seller-on-network-progress',
    element: <SellerOnNetworkProgress />,
  },
  {
    path: 'alpha/logistics-progress',
    element: <LogisticsProgress />,
  },
  {
    path: 'alpha/logistics-buyer-progress',
    element: <LogisticsBuyerProgress />,
  },

  {
    path: 'alpha/feedback',
    element: <Feedback />,
  },
  {
    path: 'alpha/terms-&-conditions',
    element: <TermsConditions />,
  },
  {
    path: 'alpha/order-tracking',
    element: <OrderTracking />,
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]
