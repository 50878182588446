import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #f9f9fa;
  height: 100vh;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100% - 150px);
`
export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
`
export const NameWrapper = styled.div`
  width: 289px;
  background: linear-gradient(92deg, #1c75bc 6.62%, #00aeef 51.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px;
`
export const Name = styled.h3`
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  width: 300px;
  height: 67vh;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: var(--Basic-white, #fff);
  gap: 10px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 55vh;
  }
`

export const RightWrapper = styled.div`
  gap: 4px;
`

export const HeadingWrapper = styled.div`
  width: 418px;
  height: 44px;

  margin: 5px;
`
export const Heading = styled.p`
  color: #606161;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
`

export const RightContainer = styled.div`
  width: 69vw;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: #fff;
  height: 67vh;
  padding: 20px;
  @media (max-width: 1200px) {
    width: 62vw;
    height: 55vh;
  }
`
export const RightSection = styled.div`
  height: 100%;
`

export const StepsWrapper = styled.div``

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 90%;
  justify-content: start;
`
export const ContentHeadWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding: 20px 10px;
  span {
    color: rgb(33, 173, 232);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }
`
export const ContentHead = styled.p`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`
export const Content = styled.p`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 10px;
`
export const HighlightedContent = styled.p`
  color: rgb(33, 173, 232);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 10px;
  margin-bottom: 8px;
`
