/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { boolean } from 'yup'

const initialState = {
  testData: {},
  testId: '',
  flowName: '',
  flowId: '',
  htmlResponse: '',
  buyerDelivery: boolean,
}

const buyerDataSlice: any = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    updateTestData: (state, action) => {
      state.testData = action.payload
    },
    updateTestId: (state, action) => {
      state.testId = action.payload
    },
    updateBuyerDelivery: (state, action) => {
      state.buyerDelivery = action.payload
    },
    updateFlowName: (state, action) => {
      state.flowName = action.payload
    },
    updateFlowId: (state, action) => {
      state.flowId = action.payload
    },

    updateHtmlResponse: (state, action) => {
      state.htmlResponse = action.payload
    },
    updateBuyerData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetBuyerData: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateTestData,
  updateTestId,
  updateBuyerData,
  resetBuyerData,
  updateFlowName,
  updateFlowId,
  updateHtmlResponse,
  updateBuyerDelivery,
} = buyerDataSlice.actions

export default buyerDataSlice.reducer
