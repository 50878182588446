import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input, Select, notification } from 'antd'
const { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } = require('react-simple-captcha')
import { HOME_VALIDATION_SCHEMA } from 'validations'
import useGet from 'hooks/useGet'
// import Watermark from 'assets/svg/watermark'
import {
  domainSelection,
  environmentSelection,
  homeVersionSelectBox,
  roleSelection,
  transactionSelection,
} from 'constants/select'
import APIS from 'constants/api'
import { resetBuyerFlow } from 'store/buyerFlowSlice'
import { resetSellerOffNetworkData } from 'store/sellerDataSlice'
import { resetLogisticsData } from 'store/logisticsDataSlice'
import { resetLogisticsFlow } from 'store/logisticsFlowSlice'
import { resetsellerFlow } from 'store/sellerFlowSlice'
import { resetBuyerData } from 'store/buyerDataSlice'
import { resetSellerOnNetworkData } from 'store/sellerOnNetworkDataSlice'
import { resetSellerOnNetworkFlow } from 'store/sellerOnNetworkFlowSlice'
import Navbar from 'components/Navbar'
// import Stepper from 'components/Stepper'
import CustomButton from 'components/Button'
import Footer from 'components/Footer'
import { ErrorMessage } from 'styles/components/FormModal'
import {
  Container,
  FormWrapper,
  // Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightSection,
  RightWrapper,
  // StepsWrapper,
  Wrapper,
  InputWrapper,
  FormRowWrapper,
  Label,
  ButtonWrapper,
  FormHeading,
  FeedbackWrapper,
  CaptchaWrapper,
  ContentHead,
  Content,
  ContentContainer,
} from 'styles/pages/home'
import { updateEnvironment } from 'store/homeDataSlice'
import { Routes } from 'interface'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

const Home = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: sessionData, isSuccess } = useGet('Session_id', `${APIS.CREATE_SESSION}`)

  const [domainSelect, setDomainSelect] = useState('')
  const [roleSelect, setRoleSelect] = useState('')
  const [versionSelect, setVersionSelect] = useState('')
  // const [next, setNext] = useState(false)

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
      duration: 3,
    })
  }
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    dispatch(resetBuyerFlow([]))
    dispatch(resetBuyerData([]))
    dispatch(resetLogisticsFlow([]))
    dispatch(resetLogisticsData([]))
    dispatch(resetsellerFlow([]))
    dispatch(resetSellerOffNetworkData([]))
    dispatch(resetSellerOnNetworkFlow([]))
    dispatch(resetSellerOnNetworkData([]))
    window.localStorage.clear()

    if (isSuccess) {
      const sessionId = sessionData.session_id || '1'
      localStorage.setItem('sessionId', sessionId)
    }
  }, [isSuccess])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(HOME_VALIDATION_SCHEMA),
    defaultValues: {},
  })

  const onSubmit = (data: any) => {
    const envi = data.environment
    dispatch(updateEnvironment(envi))
    // setNext(true)
    try {
      const { domain, role, environment } = data
      if (validateCaptcha(data?.captcha)) {
        delete data.captcha

        const routes: Routes = {
          Retail: {
            'Seller-Np': 'alpha/seller-testing',
            'Buyer-Np': 'alpha/buyer-testing',
          },
          Logistics: {
            'Seller-Np': 'alpha/logistics-testing',
            'Buyer-Np': 'alpha/Logistics-buyer-testing',
          },
          Credit: {
            'Seller-Np': 'https://pramaan.ondc.org/beta',
            'Buyer-Np': 'https://pramaan.ondc.org/beta',
          },
          Insurance: {
            'Seller-Np': 'https://pramaan.ondc.org/beta',
            'Buyer-Np': 'https://pramaan.ondc.org/beta',
          },
          'Unreserved Ticket Booking': {
            'Seller-Np': 'https://pramaan.ondc.org/beta',
            'Buyer-Np': 'https://pramaan.ondc.org/beta',
          },
          'Ride Hailing': {
            'Seller-Np': 'https://pramaan.ondc.org/beta',
            'Buyer-Np': 'https://pramaan.ondc.org/beta',
          },
          'Reserved Ticket Booking': {
            'Seller-Np': 'https://pramaan.ondc.org/beta',
            'Buyer-Np': 'https://pramaan.ondc.org/beta',
          },
          Investments: {
            'Seller-Np': 'https://pramaan.ondc.org/beta',
            'Buyer-Np': 'https://pramaan.ondc.org/beta',
          },
        }

        if (routes[domain]?.[role]) {
          if (
            domain === 'Credit' ||
            domain === 'Insurance' ||
            domain === 'Unreserved Ticket Booking' ||
            domain === 'Ride Hailing' ||
            domain === 'Reserved Ticket Booking' ||
            domain === 'Investments'
          ) {
            if (environment === 'staging') {
              window.location.href = `${routes[domain][role]}/${environment}/gui`
            } else if (environment === 'pre-prod') {
              window.location.href = `${routes[domain][role]}/preprod/gui`
            } else {
              openNotificationWithIcon('error', 'Invalid combination!', '')
              reset({ captcha: '' })
            }
          } else {
            navigate(routes[domain][role])
          }
        } else {
          openNotificationWithIcon('error', 'Invalid combination!', '')
          reset({ captcha: '' })
        }
      } else {
        openNotificationWithIcon('error', 'Invalid Captcha!', '')
        reset()
        setDomainSelect('')
        setRoleSelect('')
        setVersionSelect('')
      }

      reset()
    } catch (error) {
      return error
    }
  }

  const handleDomainSelectChange = (value: any) => {
    setDomainSelect(value)
  }
  const handleVersionSelectChange = (value: any) => {
    setVersionSelect(value)
  }
  const handleRoleSelectChange = (value: any) => {
    setRoleSelect(value)
  }

  useEffect(() => {
    loadCaptchaEnginge(6, 'rgb(240, 240, 240)')
  }, [])

  return (
    <>
      <Wrapper>
        {contextHolder}
        <Navbar />

        <Container>
          <LeftWrapper>
            <NameWrapper>
              <Name>Pramaan</Name>
            </NameWrapper>
            <LeftContainer>
              <ContentContainer>
                <ContentHead> About Pramaan</ContentHead>
                <Content>
                  {' '}
                  Pramaan is a suite of self-serve utilities to help Network Participants test integration on the
                  network either in staging or preprod environment.
                </Content>
                <Content>
                  {' '}
                  Using Pramaan is easy. Fill details of the application and configuration to be tested including
                  Subscriber ID and Subscriber URI as entered within respective registry and proceed as guided{' '}
                </Content>
                <Content>
                  A Pramaan ID is generated for every run, and a report will be available at the end of the run.
                  Technical teams can review the report and fix issues mentioned within the report and re-run once all
                  the issues are fixed. Continue the process till a no-error report is generated. Quote the Pramaan ID
                  once successful no-error report is generated.
                </Content>
              </ContentContainer>
              {/* <StepsWrapper>   */}
              {/* <Stepper next={next} /> */}
              {/* </StepsWrapper> */}
              {/* <Watermark /> */}
              <FeedbackWrapper>
                <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
              </FeedbackWrapper>
            </LeftContainer>
          </LeftWrapper>
          <RightWrapper>
            <HeadingWrapper>{/* <Heading>Select Module For a Configuration</Heading> */}</HeadingWrapper>
            <RightContainer>
              <RightSection>
                <HeadingWrapper>
                  <FormHeading>
                    Select appropriate options from below for the software that you want to test
                  </FormHeading>
                </HeadingWrapper>
                <FormWrapper>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormWrapper>
                      <FormRowWrapper>
                        <InputWrapper>
                          <Label>Domain *</Label>
                          <Controller
                            name="domain"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Select Domain"
                                size="large"
                                style={{ width: '100%' }}
                                {...field}
                                value={domainSelect || undefined}
                                onChange={(value) => {
                                  field.onChange(value)
                                  handleDomainSelectChange(value)
                                }}
                                options={domainSelection}
                              />
                            )}
                          />
                          {errors.domain && <ErrorMessage>{errors?.domain?.message}</ErrorMessage>}
                        </InputWrapper>
                        <InputWrapper>
                          <Label>Role *</Label>
                          <Controller
                            name="role"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Select Role Type"
                                size="large"
                                style={{ width: '100%' }}
                                {...field}
                                value={roleSelect || undefined}
                                onChange={(value) => {
                                  field.onChange(value)
                                  handleRoleSelectChange(value)
                                }}
                                options={roleSelection[domainSelect]}
                              />
                            )}
                          />
                          {errors.role && <ErrorMessage>{errors?.role?.message}</ErrorMessage>}
                        </InputWrapper>
                      </FormRowWrapper>
                    </FormWrapper>
                    <FormWrapper>
                      <FormRowWrapper>
                        <InputWrapper>
                          <Label>Transaction Type *</Label>
                          <Controller
                            name="transaction_type"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Select Transaction Type"
                                size="large"
                                style={{ width: '100%' }}
                                {...field}
                                options={transactionSelection}
                              />
                            )}
                          />
                          {errors.transaction_type && <ErrorMessage>{errors?.transaction_type?.message}</ErrorMessage>}
                        </InputWrapper>
                        <InputWrapper>
                          <Label>Api Version *</Label>
                          <Controller
                            name="version"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Select Api Version"
                                size="large"
                                style={{ width: '100%' }}
                                {...field}
                                value={versionSelect || undefined}
                                onChange={(value) => {
                                  field.onChange(value)
                                  handleVersionSelectChange(value)
                                }}
                                options={homeVersionSelectBox[domainSelect]}
                              />
                            )}
                          />
                          {errors.version && <ErrorMessage>{errors?.version?.message}</ErrorMessage>}
                        </InputWrapper>
                      </FormRowWrapper>
                    </FormWrapper>
                    <FormWrapper>
                      <FormRowWrapper>
                        <InputWrapper>
                          <Label>Environment *</Label>
                          <Controller
                            name="environment"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Select Environment Type"
                                size="large"
                                style={{ width: '100%' }}
                                {...field}
                                options={environmentSelection}
                              />
                            )}
                          />
                          {errors.environment && <ErrorMessage>{errors?.environment?.message}</ErrorMessage>}
                        </InputWrapper>
                      </FormRowWrapper>
                    </FormWrapper>
                    <FormWrapper>
                      <FormRowWrapper>
                        <div className="form-group">
                          <div className="">
                            <div
                              className="captcha-container"
                              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                              <LoadCanvasTemplateNoReload />

                              <div className="input-block">
                                <CaptchaWrapper>
                                  <Controller
                                    name="captcha"
                                    control={control}
                                    render={({ field }) => (
                                      <Input {...field} placeholder="Enter Captcha " size="large" />
                                    )}
                                  />
                                  <ErrorMessage>{errors?.captcha?.message}</ErrorMessage>
                                </CaptchaWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormRowWrapper>
                    </FormWrapper>
                    <FormWrapper>
                      <FormRowWrapper>
                        <ButtonWrapper>
                          <CustomButton variant="contained" label={'Start'} />
                        </ButtonWrapper>
                      </FormRowWrapper>
                    </FormWrapper>
                  </form>
                </FormWrapper>
              </RightSection>
            </RightContainer>
          </RightWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}

export default Home
