import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Watermark from 'assets/svg/watermark'
import Info from 'assets/svg/info'
import BuyerForm from 'components/BuyerForm'
import Navbar from 'components/Navbar'
import CustomButton from 'components/Button'
import Footer from 'components/Footer'
import Stepper from 'components/Stepper'
import InstructionList from 'components/InstructionList'
import { resetBuyerFlow } from 'store/buyerFlowSlice'
import {
  ButtonWrapper,
  InfoWrapper,
  RightHeading,
  Container,
  FeedbackWrapper,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightSection,
  RightWrapper,
  StepsWrapper,
  Wrapper,
} from 'styles/pages/buyerTesting'
import { resetOnNetworkBuyerFlow } from 'store/buyerOnNetworkFlowSlice'

const BuyerTesting = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [next, setNext] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleClick = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    dispatch(resetBuyerFlow([]))
    dispatch(resetOnNetworkBuyerFlow([]))
    setNext(true)
  }, [])

  return (
    <>
      <Wrapper>
        <Navbar />

        <Container>
          <LeftWrapper>
            <NameWrapper>
              <Name>Pramaan</Name>
            </NameWrapper>
            <LeftContainer>
              <StepsWrapper>
                <Stepper current={1} next={next} navi="/" />
              </StepsWrapper>
              <Watermark />
              <FeedbackWrapper>
                <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
              </FeedbackWrapper>
            </LeftContainer>
          </LeftWrapper>
          <RightWrapper>
            <HeadingWrapper>
              <Heading>Fill Application Details</Heading>
            </HeadingWrapper>
            <RightContainer>
              <RightSection>
                <InfoWrapper>
                  <Info />
                  <RightHeading>Read this before you proceed further</RightHeading>
                </InfoWrapper>
                <InstructionList />

                <ButtonWrapper>
                  <CustomButton label={'Fill Application Details'} variant="contained" onClick={handleClick} />
                </ButtonWrapper>
              </RightSection>
            </RightContainer>
          </RightWrapper>
        </Container>
        <BuyerForm
          isModalOpen={modalOpen}
          setIsModalOpen={setModalOpen}
          cancelBtnLabel={'Cancel'}
          confirmBtnLabel="Proceed further"
          buyerFlag
        />
        <Footer />
      </Wrapper>
    </>
  )
}

export default BuyerTesting
