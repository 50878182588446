import { v4 as uuid } from 'uuid'

const transaction_id = uuid()

export const searchData = {
  context: {
    action: 'search',
    country: 'IND',
    city: 'std:0172',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const selectData = {
  context: {
    action: 'select',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const initData = {
  context: {
    action: 'init',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const confirmData = {
  context: {
    action: 'confirm',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const cancelData = {
  context: {
    action: 'cancel',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const statusData = {
  context: {
    action: 'status',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const trackData = {
  context: {
    action: 'track',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const updateData = {
  context: {
    action: 'update',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const issueData = {
  context: {
    action: 'issue',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}

export const issueStatusData = {
  context: {
    action: 'issue_status',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}
export const infoData = {
  context: {
    action: 'info',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}
export const ratingData = {
  context: {
    action: 'rating',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}
export const catalog_errorData = {
  context: {
    action: 'catalog_error',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}
export const catalog_rejection = {
  context: {
    action: 'catalog_rejection',
    bap_id: process.env.REACT_APP_BAP_ID,
    bap_uri: process.env.REACT_APP_BAP_URI,
    transaction_id: transaction_id,
    message_id: uuid(),
    city: 'std:0172',
    country: 'IND',
    timestamp: new Date(),
    ttl: 'PT30S',
  },
}
