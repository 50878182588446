import { useNavigate } from 'react-router-dom'
import { ConfigProvider, Tooltip } from 'antd'
import logo from 'assets/images/Logo.png'
import Chat from 'assets/svg/chat'
import Document from 'assets/svg/document'
import {
  HighlightedText,
  Link,
  LogoWrapper,
  NavbarContainer,
  NavbarContent,
  NavbarWrapper,
  Text,
  TextWrapper,
  TooltipContainer,
} from 'styles/components/Navbar'

const Navbar = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/')
  }
  const text = (
    <TooltipContainer>
      <Text>In Case of any assistance related to this utility, Please join below slack channel:-</Text>
      <HighlightedText> Slack Channel: #ext-pramaan-qa</HighlightedText>
      <TextWrapper>
        <Text>Or you can reach out to us at :- </Text>
        <HighlightedText> Support.pramaan@ondc.org </HighlightedText>
      </TextWrapper>
      <TextWrapper>
        <Text>In case you are not on ONDC workspace, Please drop an email at </Text>
        <HighlightedText> team@ondc.org</HighlightedText>
      </TextWrapper>
    </TooltipContainer>
  )
  const googleDriveLink =
    'https://docs.google.com/document/d/1GofIfH2_wxZgc4_I6PZaw_QGrvaOTQv3-wqWT7DfjLc/edit?usp=sharing'
  const aboutPramaanLink =
    'https://docs.google.com/document/d/1LrcTQhlowhF4f6HFx4FcaEuWHDHNaojXVENGQLXgvFY/edit?usp=sharing'
  const detailLink =
    'https://docs.google.com/spreadsheets/d/1VH7LbIda44T-Ihgy2Xh4ve_8ILPHHFFwFgc5Y7EE7qI/edit?usp=sharing'
  const document = (
    <TooltipContainer>
      <TextWrapper>
        <Text>About Pramaan:</Text>
        <Link href={aboutPramaanLink} target="_blank">
          {/* Open Google Drive Link */}
          https://docs.google.com/document/d/1LrcTQhlowhF4f6HFx4FcaEuWHDHNaojXVENGQLXgvFY/edit?usp=sharing
        </Link>
      </TextWrapper>
      <TextWrapper>
        <Text>QA Test Case:</Text>
        <Link href={googleDriveLink} target="_blank">
          {/* Open Google Drive Link */}
          https://docs.google.com/document/d/1GofIfH2_wxZgc4_I6PZaw_QGrvaOTQv3-wqWT7DfjLc/edit?usp=sharing
        </Link>
      </TextWrapper>
      <TextWrapper>
        <Text>Details of Catalog for Retail Mock Seller (May be used for testing Buyer App):</Text>
        <Link href={detailLink} target="_blank">
          {/* Catalog Link */}
          https://docs.google.com/spreadsheets/d/1VH7LbIda44T-Ihgy2Xh4ve_8ILPHHFFwFgc5Y7EE7qI/edit?usp=sharing
        </Link>
      </TextWrapper>
    </TooltipContainer>
  )

  return (
    <ConfigProvider
      theme={{
        token: { colorText: '#21ade8' },
      }}
    >
      <NavbarWrapper>
        <NavbarContainer>
          <LogoWrapper>
            <img src={logo} alt="ONDC" width={'100%'} onClick={() => handleClick()} />
          </LogoWrapper>
          <NavbarContent>
            <Tooltip placement="bottomLeft" title={document} color=" rgb(240, 240, 240)">
              <Document />
            </Tooltip>
            <Tooltip placement="bottomLeft" title={text} color=" rgb(240, 240, 240)">
              <Chat />
            </Tooltip>
          </NavbarContent>
        </NavbarContainer>
      </NavbarWrapper>
    </ConfigProvider>
  )
}

export default Navbar
