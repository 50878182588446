import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: calc(100vh - 68px);
  justify-content: center;
  flex-direction: column;
`
export const NumberWrapper = styled.div``
export const Number = styled.h1`
  font-size: 150px;
  margin-bottom: 10px;
`

export const Text = styled.div`
  color: rgb(25, 106, 171);
  font-size: 20px;
`
export const Link = styled.a`
  padding: 20px;
  color: rgb(25, 106, 171);
  font-size: 14px;
`
export const Wrapper = styled.div`
  background: #f9f9fa;
  height: 100vh;
`
