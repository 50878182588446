import styled from 'styled-components'

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 20px;
`
export const Heading = styled.h3`
  font-size: 32px;
  margin-bottom: 10px;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 2px;
  align-items: center;
  gap: 10px;
  overflow: hidden scroll;
  max-height: 500px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background: #d9d9d9;
  }
`
export const TextWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(67, 65, 65, 0.09);
  border-radius: 5px;
  /* width: 100%; */
  justify-content: space-between;
`
export const TextContainer = styled.div``
export const Text = styled.p`
  font-weight: 500;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  button {
    width: 75px;
    height: 35px;
    background: rgb(25, 106, 171);
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;

    border-radius: 8px;
    text-align: center;
  }
`
