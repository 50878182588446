import React from 'react'
import { Bullet, Instruction, Instructions, InstructionsWrapper, ContentContainer } from 'styles/pages/sellerTesting'

const InstructionList = () => {
  return (
    <>
      {' '}
      <InstructionsWrapper>
        <ContentContainer>
          <Instruction> Pre-requisites:</Instruction>

          <Instructions>
            <Bullet>1.</Bullet>
            <Instruction>
              {' '}
              Ensure you have subscribed to the registry for the environment for which you are carrying out the testing
            </Instruction>
          </Instructions>
          <Instructions>
            <Bullet>2.</Bullet>
            <Instruction>
              Whitelist the following domain -<span> https://pramaan.ondc.org/alpha/mock-server</span>, as API calls
              will be made from here
            </Instruction>
          </Instructions>
        </ContentContainer>
        <div>
          <Instruction>Points to note :</Instruction>
          <Instructions>
            <Bullet>1.</Bullet>
            <Instruction>
              Fill application related details in order to proceed with execution of tests. Pramaan ID will be generated
              after you submit the form successfully.
            </Instruction>
          </Instructions>
          <Instructions>
            <Bullet>2.</Bullet>
            <Instruction>
              During execution of tests, time-bound pop-up messages will be appear (ensure pop-up blocker is disabled).
              You will be expected to take appropriate action as mentioned within the pop-up message. For example, while
              testing of seller app, seller app will be required to change status of order from &quot;Confirmed&quot; to
              &quot;Ready to Ship&quot;
            </Instruction>
          </Instructions>
          <Instructions>
            <Bullet>3.</Bullet>
            <Instruction>
              Report will be generated with Pramaan ID post completion of a test. Download this report and take
              appropriate action for all failed cases. Repeat till all tests are marked as PASS within report
            </Instruction>
          </Instructions>
          <Instructions>
            <Bullet>4.</Bullet>
            <Instruction>You can reach out for support via slack-channel on the top right hand-side </Instruction>
          </Instructions>
          <Instructions>
            <Bullet>5.</Bullet>
            <Instruction>
              Lastly, we would be happy to get your feedback, you can submit your feedback from the bottom left corner
              of the page
            </Instruction>
          </Instructions>
        </div>

        {/* <FirstInstructionWrapper>
                    <Bullet></Bullet>
                    <FirstInstruction>
                      Please ensure that https://pramaan.ondc.org/alpha/mock-server/ is whitelisted before proceeding
                    </FirstInstruction>
                  </FirstInstructionWrapper>
                  {InstructionsList.map((inst: any, index: number) => (
                    <Instructions key={index}>
                      <Bullet></Bullet>
                      <Instruction>{inst}</Instruction>
                    </Instructions>
                  ))} */}
      </InstructionsWrapper>
    </>
  )
}

export default InstructionList
