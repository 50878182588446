/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { boolean } from 'yup'

const initialState = {
  testData: {},
  testId: '',
  flowName: '',
  flowId: '',
  htmlResponse: '',
  onNetwork: boolean,
}

const sellerDataSlice: any = createSlice({
  name: 'metadata',
  initialState,

  reducers: {
    updateSellerOffNetworkTestData: (state, action) => {
      state.testData = action.payload
    },
    updateSellerOffNetworkTestId: (state, action) => {
      state.testId = action.payload
    },
    updateOnNetwork: (state, action) => {
      state.onNetwork = action.payload
    },
    updateSellerOffNetworkFlowName: (state, action) => {
      state.flowName = action.payload
    },

    updateSellerOffNetworkHtmlResponse: (state, action) => {
      state.htmlResponse = action.payload
    },
    updateSellerOffNetworkData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetSellerOffNetworkData: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateSellerOffNetworkTestData,
  updateSellerOffNetworkTestId,
  updateSellerOffNetworkFlowName,
  updateSellerOffNetworkData,
  updateSellerOffNetworkHtmlResponse,
  resetSellerOffNetworkData,
  updateOnNetwork,
} = sellerDataSlice.actions

export default sellerDataSlice.reducer
