import styled from 'styled-components'

export const RightContainer = styled.div`
  width: 69vw;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: #fff;
  height: 67vh;
  padding: 20px;
  @media (max-width: 1200px) {
    width: 62vw;
    height: 55vh;
  }
`
export const Instruction = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 20px;
  span {
    color: var(--Black, #606161);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 2px;
    span {
      color: rgb(33, 173, 232);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 2px;
    }
  }
`

export const InstructionsWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  @media (max-width: 1200px) {
    // height: 40vh;
  }
`
