import Watermark from 'assets/svg/watermark'
import Stepper from 'components/Stepper'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import {
  Container,
  Content,
  ContentHead,
  ContentHeadWrapper,
  ContentWrapper,
  Heading,
  HeadingWrapper,
  HighlightedContent,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightSection,
  RightWrapper,
  StepsWrapper,
  Wrapper,
} from 'styles/pages/feedback'
import { useEffect, useState } from 'react'

const Feedback = () => {
  const [next, setNext] = useState(false)
  useEffect(() => {
    setNext(true)
  }, [])

  return (
    <Wrapper>
      <Navbar />

      <Container>
        <LeftWrapper>
          <NameWrapper>
            <Name>Pramaan</Name>
          </NameWrapper>
          <LeftContainer>
            <StepsWrapper>
              <Stepper current={3} next={next} navi="/" />
            </StepsWrapper>
            <Watermark />
          </LeftContainer>
        </LeftWrapper>
        <RightWrapper>
          <HeadingWrapper>
            <Heading>Submit Feedback</Heading>
          </HeadingWrapper>
          <RightContainer>
            <RightSection>
              <ContentWrapper>
                <ContentHeadWrapper>
                  <ContentHead>
                    Please drop your feedback/ suggestions related to Pramaan at <span>Support.pramaan@ondc.org</span>
                  </ContentHead>
                </ContentHeadWrapper>
                <Content>In Case of any assistance related to this utility, Please join below slack channel</Content>
                <HighlightedContent>Slack Channel: #ext-pramaan-qa</HighlightedContent>
                <ContentHeadWrapper>
                  <ContentHead>
                    In case you are not on ONDC workspace, Please drop an email at <span>team@ondc.org</span>{' '}
                  </ContentHead>
                </ContentHeadWrapper>
              </ContentWrapper>
            </RightSection>
          </RightContainer>
        </RightWrapper>
      </Container>
      <Footer />
    </Wrapper>
  )
}

export default Feedback
