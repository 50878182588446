import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Select, Input } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import usePost from '../../hooks/usePost'
import { LOGISTICS_TEST_VALIDATION_SCHEMA } from 'validations'
import { FormModelProps, ILogisticsPayload } from 'interface'
import APIS from 'constants/api'
import {
  categoryLogisticsSelectBox,
  logisticsDomainSelectBox,
  npTypeLogisticsSelectBox,
  versionSelectBox,
} from 'constants/select'
import { updateData } from 'store/logisticsDataSlice'
import CustomButton from 'components/Button'
import Loader from 'components/Loader'
import {
  ButtonWrapper,
  ErrorMessage,
  FormInnerContainer,
  FormItemWrapper,
  FormWrapper,
  HeaderWrapper,
  ModalBody,
  ValueWrapper,
  Label,
} from 'styles/components/LogisticsForm'
import { clearAllEvents } from 'store/logsSlice'

const LogisticsForm = ({ isModalOpen, setIsModalOpen, cancelBtnLabel, confirmBtnLabel }: FormModelProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { mutateAsync, isLoading, isSuccess } = usePost()
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LOGISTICS_TEST_VALIDATION_SCHEMA),
    defaultValues: {
      start_gps_location: '',
      start_area_code: '',
      end_gps_location: '',
      end_area_code: '',
      subscriber_uri: '',
      subscriber_id: '',
    },
  })

  const handleClearAllEvents = () => {
    dispatch(clearAllEvents())
  }
  const onSubmit = async (data: any) => {
    handleClearAllEvents()
    try {
      let metaData: { testData: any; testId: string } = {
        testData: {},
        testId: '',
      }
      const payload: ILogisticsPayload = {
        domain: data.domain,
        np_type: data.np_type,
        version: data.version,
        logistics_category: data.logistics_category,
        location: {
          start: {
            gps: data.start_gps_location,
            area_code: data.start_area_code,
          },
          end: {
            gps: data.end_gps_location,
            area_code: data.end_area_code,
          },
        },

        bpp_uri: data.subscriber_uri,
        bpp_id: data.subscriber_id,
        session_id: window.localStorage.getItem('sessionId') || '1',
        environment_type: environment,
      }

      metaData = { ...metaData, testData: payload }

      const response = await mutateAsync({
        url: APIS.POST_TEST,
        payload: payload,
      })

      metaData = { ...metaData, testId: String(response?.test_id) }

      dispatch(updateData(metaData))
      navigate('/alpha/logistics-progress')
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (isSuccess) {
      reset()
      setIsModalOpen(false)
    }
  }, [isSuccess])

  const handleCancel = () => {
    setIsModalOpen(false)
    reset()
  }

  return (
    <>
      {isLoading ? (
        <Loader isModalOpen={true} text={'Generating flows'} />
      ) : (
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          width={'572px'}
          styles={{ body: { height: '552px', color: '#606161' } }}
          title="Fill Application Details"
          footer={null}
          maskClosable={false}
        >
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInnerContainer>
                <FormWrapper>
                  <Label>Domain *</Label>
                  <Controller
                    name="domain"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Domain"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={logisticsDomainSelectBox}
                      />
                    )}
                  />
                  {errors.domain && <ErrorMessage>{errors?.domain?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Version *</Label>
                  <Controller
                    name="version"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Version"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={versionSelectBox}
                      />
                    )}
                  />
                  {errors.version && <ErrorMessage>{errors?.version?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>NP Type *</Label>
                  <Controller
                    name="np_type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select NP_Type"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={npTypeLogisticsSelectBox}
                      />
                    )}
                  />

                  {errors.np_type && <ErrorMessage>{errors?.np_type?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Category *</Label>
                  <Controller
                    name="logistics_category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Select category"
                        size="large"
                        style={{ width: '100%' }}
                        {...field}
                        options={categoryLogisticsSelectBox}
                      />
                    )}
                  />

                  {errors.logistics_category && <ErrorMessage>{errors?.logistics_category?.message}</ErrorMessage>}
                </FormWrapper>

                <FormWrapper>
                  <Label>Start Location*</Label>
                  <FormItemWrapper>
                    <ValueWrapper>
                      <Controller
                        name="start_gps_location"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter GPS Location" size="large" />}
                      />
                      {errors.start_gps_location && <ErrorMessage>{errors?.start_gps_location?.message}</ErrorMessage>}
                    </ValueWrapper>
                    <HeaderWrapper>
                      <Controller
                        name="start_area_code"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter  Area code" size="large" />}
                      />
                      {errors.start_area_code && <ErrorMessage>{errors?.start_area_code?.message}</ErrorMessage>}
                    </HeaderWrapper>
                  </FormItemWrapper>
                </FormWrapper>
                <FormWrapper>
                  <Label>End Location*</Label>
                  <FormItemWrapper>
                    <ValueWrapper>
                      <Controller
                        name="end_gps_location"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter GPS Location" size="large" />}
                      />
                      {errors.end_gps_location && <ErrorMessage>{errors?.end_gps_location?.message}</ErrorMessage>}
                    </ValueWrapper>
                    <HeaderWrapper>
                      <Controller
                        name="end_area_code"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter  Area code" size="large" />}
                      />
                      {errors.end_area_code && <ErrorMessage>{errors?.end_area_code?.message}</ErrorMessage>}
                    </HeaderWrapper>
                  </FormItemWrapper>
                </FormWrapper>

                <FormWrapper>
                  <Label>Subscriber URI *</Label>
                  <Controller
                    name="subscriber_uri"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter URI" size="large" />}
                  />
                  {errors.subscriber_uri && <ErrorMessage>{errors?.subscriber_uri?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Subscriber Id *</Label>
                  <Controller
                    name="subscriber_id"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter Id" size="large" />}
                  />
                  {errors.subscriber_id && <ErrorMessage>{errors?.subscriber_id?.message}</ErrorMessage>}
                </FormWrapper>
              </FormInnerContainer>
              <ButtonWrapper>
                <CustomButton label={cancelBtnLabel} variant="outline" onClick={handleCancel} />
                {confirmBtnLabel && <CustomButton label={confirmBtnLabel} variant="gradient" />}
              </ButtonWrapper>
            </form>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default LogisticsForm
