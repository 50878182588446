import * as Yup from 'yup'

export const SELLER_TEST_VALIDATION_SCHEMA = Yup.object().shape({
  domain: Yup.string().required('Please select Domain'),
  serviceable_gps: Yup.string().required('Please enter serviceable gps'),
  np_type: Yup.string().required('Please select NpType'),
  version: Yup.string().required('Please select Version'),
  subscriber_uri: Yup.string()
    .required('Please enter subscriber Url')
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
      'Please enter valid Url',
    ),
  subscriber_id: Yup.string().required('Please enter subscriber id'),
  serviceable_pin_code: Yup.string()
    .matches(/^\d{6}$/, {
      message: 'Please enter valid pin code',
      excludeEmptyString: true,
    })
    .required('Please enter pincode'),
  item_name: Yup.string().required('Please enter item name'),
  provider_id: Yup.string().required('Please enter provider id'),
  onNetwork: Yup.boolean(),
  logistics_bap_uri: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
    'Please enter valid Url',
  ),
  logistics_bap_id: Yup.string(),
  type_of_hits: Yup.string(),

  // onNetwork: Yup.boolean(),
  // logistics_bap_uri: Yup.string()
  //   .required('Please enter logistics bap Url')
  //   .matches(/(https?:\/\/)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?].*)?/, 'Please enter a valid Url'),
  // logistics_bap_id: Yup.string().required('Please enter logistics bap id'),
  // type_of_hits: Yup.string().when('onNetwork', {
  //   is: true,
  //   then: Yup.string().required('Please enter type of hits'),
  //   otherwise: Yup.string().notRequired(), // Optional if onNetwork is false or undefined
  // }),
})
export const SELLER_TEST_ON_NETWORK_VALIDATION_SCHEMA = Yup.object().shape({
  domain: Yup.string().required('Please select Domain'),
  serviceable_gps: Yup.string().required('Please enter serviceable gps'),
  np_type: Yup.string().required('Please select Np_type'),
  version: Yup.string().required('Please select Version'),
  subscriber_uri: Yup.string()
    .required('Please enter subscriber Url')
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
      'Please enter valid Url',
    ),
  logistics_bap_uri: Yup.string()
    .required('Please enter logistics bap Url')
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
      'Please enter valid Url',
    ),
  subscriber_id: Yup.string().required('Please enter subscriber id'),
  logistics_bap_id: Yup.string().required('Please enter logistics bap id'),
  serviceable_pin_code: Yup.string()
    .matches(/^\d{6}$/, {
      message: 'Please enter valid pin code',
      excludeEmptyString: true,
    })
    .required('Please enter pincode'),
  item_name: Yup.string().required('Please enter item name'),
  type_of_hits: Yup.string(),
  isMsn: Yup.boolean(),
  provider_id: Yup.string().required('Please enter provider id'),
})
export const BUYER_TEST_VALIDATION_SCHEMA = Yup.object().shape({
  domain: Yup.string().required('Please select Domain'),
  np_type: Yup.string().required('Please select NpType'),
  version: Yup.string().required('Please select Version'),
  subscriber_uri: Yup.string()
    .required('Please enter subscriber Url')
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
      'Please enter valid Url',
    ),
  subscriber_id: Yup.string().required('Please enter subscriber id'),
  buyerDelivery: Yup.boolean(),
  logistics_bap_uri: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
    'Please enter valid Url',
  ),
  logistics_bap_id: Yup.string(),
  type_of_hits: Yup.string(),
})
export const LOGISTICS_BUYER_TEST_VALIDATION_SCHEMA = Yup.object().shape({
  domain: Yup.string().required('Please select Domain'),
  np_type: Yup.string().required('Please select NpType'),
  version: Yup.string().required('Please select Version'),
  subscriber_uri: Yup.string()
    .required('Please enter subscriber Url')
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
      'Please enter valid Url',
    ),
  subscriber_id: Yup.string().required('Please enter subscriber id'),
  buyerDelivery: Yup.boolean(),
  logistics_bap_uri: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
    'Please enter valid Url',
  ),
  logistics_bap_id: Yup.string(),
  type_of_hits: Yup.string(),
})
export const LOGISTICS_TEST_VALIDATION_SCHEMA = Yup.object().shape({
  domain: Yup.string().required('Please select Domain'),
  np_type: Yup.string().required('Please select NpType'),
  version: Yup.string().required('Please select Version'),
  serviceable_pin_code: Yup.string().matches(/^\d{6}$/, {
    message: 'Please enter valid pin code',
    excludeEmptyString: true,
  }),
  start_gps_location: Yup.string(),
  start_area_code: Yup.string(),
  end_gps_location: Yup.string(),
  end_area_code: Yup.string(),
  subscriber_uri: Yup.string()
    .required('Please enter subscriber Url')
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
      'Please enter valid Url',
    ),
  subscriber_id: Yup.string().required('Please enter subscriber id'),
  logistics_category: Yup.string().required('Please select category'),
})

export const HOME_VALIDATION_SCHEMA = Yup.object().shape({
  domain: Yup.string().required('Please select Domain'),
  role: Yup.string().required('Please select Role'),
  transaction_type: Yup.string().required('Please select Type'),
  version: Yup.string().required('Please select Version'),
  environment: Yup.string().required('Please select environment'),
  captcha: Yup.string().required('Please enter valid captcha'),
})
