import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g fill="#838383">
      <path d="M14.654 6.754H9.346a2.59 2.59 0 0 0-2.59 2.59v5.31a2.59 2.59 0 0 0 2.59 2.591h5.308a2.59 2.59 0 0 0 2.59-2.59V9.347a2.592 2.592 0 0 0-2.59-2.593ZM12 15.397a3.396 3.396 0 1 1 0-6.792 3.396 3.396 0 0 1 0 6.792Zm3.405-5.954a.825.825 0 1 1 0-1.65.825.825 0 0 1 0 1.65Z" />
      <path d="M12 14.076a2.076 2.076 0 1 0 0-4.152 2.076 2.076 0 0 0 0 4.152Z" />
      <path d="M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm6.567 13.654a3.918 3.918 0 0 1-3.913 3.913H9.346a3.919 3.919 0 0 1-3.913-3.913V9.346a3.919 3.919 0 0 1 3.913-3.913h5.308a3.919 3.919 0 0 1 3.913 3.913v5.308Z" />
    </g>
  </svg>
)
export default SvgComponent
