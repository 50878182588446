import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  CloseOutlined,
  CodeOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { Button, Progress, notification } from 'antd'
import { API, EventData, Flow, IbuyerPostFlow, MockFlow } from 'interface'
import { PayloadContext } from 'context'
import { LoaderContext } from 'context/loader'
import APIS from 'constants/api'
import { onNetworkBuyerPostFlows, onNetworkBuyerProgress } from 'constants/constants'
import usePost from 'hooks/usePost'
import Watermark from 'assets/svg/watermark'
import Loader from 'components/Loader'
import CustomButton from 'components/Button'
import Navbar from 'components/Navbar'
import Stepper from 'components/Stepper'
import Footer from 'components/Footer'
import {
  setBuyerOnNetworkFlow,
  updateFileGenerated,
  updateFlowStatus,
  updateOrderStatus,
} from 'store/buyerOnNetworkFlowSlice'
import {
  updateOnNetworkHtmlResponse,
  updateOnNetworkFlowId,
  updateOnNetworkFlowName,
} from 'store/buyerOnNetworkDataSlice'
import {
  Header,
  Title,
  ButtonWrapper,
  RightSection,
  FlowHeading,
  EntireFlowContainer,
  ApiWrapper,
  OrderStatusWrapper,
  OrderNameWrapper,
  Flowwrapper,
  OrderWrapper,
  Apis,
  FlowWrapper,
  ReportButtonWrapper,
  Container,
  FeedbackWrapper,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightWrapper,
  StepsWrapper,
  Wrapper,
  LoaderContainer,
} from 'styles/pages/buyerFlow'
import BuyerSelectModal from 'components/BuyerSelectModal'
import {
  ArrodianWrapper,
  ConsoleContent,
  ConsoleWrapper,
  Content,
  CrossIcon,
  LogsText,
} from 'styles/pages/flowProgress'
import { addEvent } from 'store/logsSlice'
import { itemStatusFinder } from 'helper'

type NotificationType = 'success' | 'info' | 'warning' | 'error'
const BuyerOnNetworkFlow = () => {
  const [selectModal, setSelectModal] = useState(false)
  const [timer, setTimer] = useState(false)
  const [reportLoader, setReportLoader] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [next, setNext] = useState(false)
  const [service, setService] = useState(false)
  const [flowName, setFlowName] = useState('')
  const [flowLoading, setFlowLoading] = useState(false)
  const [count, setCount] = useState(0)
  const { setLoader } = useContext(LoaderContext)
  const { payloadData } = useContext(PayloadContext)
  const { mutateAsync } = usePost()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const buyerFlowOnNetworkState = useSelector((state: any) => state.buyerOnNetworkFlow)
  const [displayConsole, setDisplayConsole] = useState(false)
  const buyerOnNetworkDataState = useSelector((state: any) => state?.buyerOnNetworkData)
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment
  const events = useSelector((state: any) => state.logs)
  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
      duration: 3,
    })
  }
  const [api, contextHolder] = notification.useNotification()

  const updateReduxOrderStatus = (event: any) => {
    const { flow_name, api, status, index } = event
    dispatch(updateOrderStatus({ flowName: flow_name, api, status, index }))
  }

  const updateReduxFlowStatus = (event: any) => {
    const { flow_name, message } = event
    dispatch(updateFlowStatus({ flowName: flow_name, message }))
  }

  const handleFileGenerateEvent = (event: any) => {
    const { flow_name } = event
    dispatch(updateFileGenerated({ flowName: flow_name, fileGenerated: true }))
  }
  const updateStatus = (input: {
    flow_name: string
    status: string
    orderTimer: string
    action: string
    api: string
    service: string
  }) => {
    if (input.service == 'logistics') {
      setService(true)
    }

    if (
      input.flow_name == 'order-fulfillment-buyer-delivery' ||
      (input.flow_name == 'order-fulfillment-buyer-delivery-RTO' &&
        input.orderTimer == 'start' &&
        input.api == 'on_select')
    ) {
      setSelectModal(true)
    } else {
      setSelectModal(false)
    }

    if (input.flow_name == 'search-and-custom-menu') {
      if (input?.orderTimer == 'start') {
        setIsWaiting(true)
      }

      if (input?.orderTimer == 'stop') {
        setIsWaiting(false)
      }

      if (input?.orderTimer == 'start' && input.action == '/select') {
        setTimer(true)
      }

      if (input?.orderTimer == 'stop' && input.action == '/select') {
        setTimer(false)
      }
    }
  }

  const handleCallback = () => {
    setLoader(true)
    const test_id = buyerOnNetworkDataState?.testId
    const url = `${process.env.REACT_APP_BASE_URL}/sse/${test_id}`
    const eventSource = new EventSource(url, { withCredentials: false })
    eventSource?.addEventListener('api_status', (event: any) => {
      setLoader(false)
      const data = JSON.parse(event.data)
      updateReduxOrderStatus(data)
      updateStatus(data)
    })
    eventSource?.addEventListener('file_generate', (event: any) => {
      setFlowLoading(false)
      setReportLoader(true)
      const data: any = JSON.parse(event.data)
      if (data?.success) {
        updateReduxFlowStatus(data)
        handleFileGenerateEvent(data)
        window.localStorage.setItem('reportFlag', 'true')
        setReportLoader(false)
        openNotificationWithIcon('success', 'File Generated Successfully', '')
      }
    })
  }

  useEffect(() => {
    setNext(true)
    if (!buyerFlowOnNetworkState.length) dispatch(setBuyerOnNetworkFlow(onNetworkBuyerProgress))
    setCount(0)
  }, [])

  const handleDownload = async (fileUrl: string, flow_name: string, test_id: number) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
      downloadLink.download = flow_name + '-' + test_id + '-' + new Date().toISOString() + '.html'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      return error
    }
  }

  const getGeneratedFile = async (flow_name: string) => {
    const test_id = buyerOnNetworkDataState?.testId
    try {
      const payload = {
        testId: test_id,
        flowName: flow_name,
      }
      const response = await mutateAsync({
        url: APIS.GET_REPORT,
        payload: payload,
        mockServer: true,
      })

      dispatch(updateOnNetworkHtmlResponse(response.data.mesg))
      handleDownload(response.data.mesg, flow_name, test_id)
    } catch (error) {
      return error
    }
  }

  const handleConsole = () => {
    const test_id = buyerOnNetworkDataState?.testId
    const url = `${process.env.REACT_APP_BASE_URL}/sseterminal/${test_id}`
    const eventSource = new EventSource(url, { withCredentials: false })
    eventSource?.addEventListener('terminal_status', (event: any) => {
      const eventData: EventData = JSON.parse(event.data)
      dispatch(addEvent(eventData))
    })

    eventSource.onerror = () => {
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  }

  const startTest = async (name: string) => {
    handleConsole()
    setDisplayConsole(true)
    setFlowLoading(true)
    setFlowName(name)
    dispatch(updateOnNetworkFlowName(name))
    setLoader(true)
    try {
      const pythonEnginePayload: Flow[] = []
      const mockpayload: MockFlow = {
        flows: [],
      }
      const parsedData = buyerOnNetworkDataState?.testData

      onNetworkBuyerPostFlows.forEach((item: IbuyerPostFlow) => {
        const apis: API[] = []
        if (name === item.flow_name) {
          item.order.forEach((item: string) => {
            const action: string = item.split('/')[1]
            const payload = payloadData[action]
            payload.context = {
              ...payload.context,
              bap_id: parsedData.bap_id,
              bap_uri: parsedData.bap_uri,
              core_version: parsedData.version,
              domain: parsedData.domain,
            }
            const obj = {
              route: item,
              payload,
            }
            apis.push(obj)
          })
          const finalObj = { ...item, test_id: buyerOnNetworkDataState?.testId, apis, environment_type: environment }
          pythonEnginePayload.push(finalObj)
        }

        const Payload = {
          ...parsedData,
          test_id: buyerOnNetworkDataState?.testId,
          flows: pythonEnginePayload,
          environment_type: environment,
        }
        Object.assign(mockpayload, Payload)
      })

      const res = await mutateAsync({
        url: APIS.POST_TEST_FLOW,
        payload: mockpayload,
        mockServer: true,
      })
      await mutateAsync({
        url: APIS.POST_FLOW,
        payload: pythonEnginePayload,
      })
      dispatch(updateOnNetworkFlowId(res?.flow_id))

      if (res.message) {
        setLoader(false)
        setSelectModal(true)
      }

      setLoader(false)
    } catch (error: any) {
      openNotificationWithIcon('error', JSON.stringify(error.message), 'Please try after some time')
      setLoader(false)
    }
  }

  const customFormat = () => <span style={{ color: '#F29C49', fontSize: '16px' }}>!</span>

  const itemStatusFinder1 = (status: string) => {
    switch (status) {
      case 'initiated':
        return (
          <LoaderContainer>
            <LoadingOutlined style={{ fontSize: '40px', color: '#1C75BC' }} />
          </LoaderContainer>
        )
      case 'success':
        return <Progress type="circle" percent={100} size={40} status="success" />

      case 'warning':
        return (
          <Progress
            type="circle"
            percent={100}
            size={40}
            strokeColor={'#F29C49'}
            status="exception"
            format={customFormat}
          />
        )
      case 'fail':
        return <Progress type="circle" percent={100} size={40} status="exception" />

      default:
        return <Progress type="circle" size={40} status="normal" />
    }
  }

  const status = buyerFlowOnNetworkState.some((item: any) => item.flowStatus === 'started')

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)
  const handleToggle = (index: number | null) => {
    setExpandedIndex(index === expandedIndex ? null : index)
  }

  return (
    <Wrapper>
      {contextHolder}
      <Navbar />

      <Container>
        <LeftWrapper>
          <NameWrapper>
            <Name>Pramaan</Name>
          </NameWrapper>
          <LeftContainer>
            <StepsWrapper>
              <Stepper current={2} next={next} navi="/buyer-testing" />
            </StepsWrapper>
            {displayConsole ? (
              <ConsoleWrapper>
                <CrossIcon onClick={() => setDisplayConsole(false)}>
                  <CloseOutlined style={{ fontSize: '12px' }} />
                </CrossIcon>
                <ConsoleContent>
                  {events[flowName]?.map((event: any, index: number) => (
                    <LogsText key={index}>
                      {event.sub_group ? (
                        <Content>
                          <ArrodianWrapper>
                            <p style={{ color: event.status === 'fail' ? 'red' : 'inherit' }}>
                              {itemStatusFinder(event.status)} {event.message}
                              {expandedIndex === index ? (
                                <UpOutlined onClick={() => handleToggle(null)} />
                              ) : (
                                <DownOutlined onClick={() => handleToggle(index)} />
                              )}
                            </p>
                            {expandedIndex === index && <p>{event.sub_group}</p>}
                          </ArrodianWrapper>{' '}
                        </Content>
                      ) : (
                        <Content>
                          {itemStatusFinder(event.status)} {event.message}
                        </Content>
                      )}
                    </LogsText>
                  ))}
                </ConsoleContent>
              </ConsoleWrapper>
            ) : (
              <>
                {' '}
                <Watermark />
                <FeedbackWrapper>
                  <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
                </FeedbackWrapper>
              </>
            )}
          </LeftContainer>
        </LeftWrapper>
        <RightWrapper>
          <HeadingWrapper>
            <Heading>Execute Testing & Review Results</Heading>
          </HeadingWrapper>
          <RightContainer>
            <RightSection>
              <Header>
                <Title>Test Scenarios</Title>
              </Header>
              <FlowWrapper>
                {buyerFlowOnNetworkState.map((flow: any, index: number) => {
                  return (
                    <EntireFlowContainer key={index}>
                      <Flowwrapper>
                        <FlowHeading>{flow.flow_name}</FlowHeading>
                        <ReportButtonWrapper>
                          <CodeOutlined
                            style={{ fontSize: '26px', color: '#21ADE8' }}
                            onClick={() => {
                              setDisplayConsole(true)
                              setFlowName(flow.flow_name)
                            }}
                          />
                          {flow.fileGenerated ? (
                            <Button
                              type="text"
                              icon={<DownloadOutlined style={{ fontSize: '18px', color: '#21ADE8' }} />}
                              size={'middle'}
                              onClick={() => getGeneratedFile(flow.flow_name)}
                            >
                              Download Report
                            </Button>
                          ) : (
                            ''
                          )}
                        </ReportButtonWrapper>

                        <ButtonWrapper>
                          {flowLoading && flow.flowStatus == 'started' ? (
                            <Button>
                              <LoaderContainer>
                                <LoadingOutlined style={{ fontSize: '20px', color: '#666666' }} />
                                Testing
                              </LoaderContainer>
                            </Button>
                          ) : (
                            <CustomButton
                              label={'Start'}
                              variant={
                                flow.flowStatus == 'completed'
                                  ? 'disabled'
                                  : buyerFlowOnNetworkState.some((item: any) => item.flowStatus === 'started')
                                  ? 'disabled'
                                  : 'contained'
                              }
                              onClick={() => startTest(flow.flow_name)}
                              disabled={flow.flowStatus == 'completed' ? true : status ? true : false}
                            />
                          )}
                        </ButtonWrapper>
                      </Flowwrapper>

                      <ApiWrapper key={index}>
                        <Apis>
                          {flow.order.map((it: any, ind: number) => {
                            return (
                              <OrderWrapper key={ind}>
                                <OrderNameWrapper>{it.api}</OrderNameWrapper>
                                <OrderStatusWrapper>{itemStatusFinder1(it.status)}</OrderStatusWrapper>
                              </OrderWrapper>
                            )
                          })}
                        </Apis>
                        <Apis>
                          {flow.logisticsOrder && <p>Logistics</p>}

                          {flow.logisticsOrder?.map((it: any, ind: number) => {
                            return (
                              <OrderWrapper key={ind}>
                                <OrderNameWrapper>{it}</OrderNameWrapper>
                              </OrderWrapper>
                            )
                          })}
                        </Apis>
                      </ApiWrapper>
                    </EntireFlowContainer>
                  )
                })}
              </FlowWrapper>
            </RightSection>
          </RightContainer>
        </RightWrapper>
      </Container>

      <BuyerSelectModal
        heading="Select Your Item From List"
        isModalOpen={selectModal || isWaiting}
        setIsModalOpen={selectModal ? setSelectModal : setIsWaiting}
        setTimer={setTimer}
        callback={handleCallback}
        flowName={flowName}
        count={count}
        setCount={setCount}
        service={service}
        setService={setService}
      />

      {timer && <Loader isModalOpen={timer} text={'Waiting for 3 minutes for select call'} />}
      {isWaiting && <Loader isModalOpen={isWaiting} text={'Waiting for 3 minutes for next search call'} />}
      {reportLoader && <Loader isModalOpen={timer} text={'Generating Report'} />}
      <Footer />
    </Wrapper>
  )
}

export default BuyerOnNetworkFlow
