import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { logisticsProgressFlows } from 'constants/constants'

interface OrderItem {
  status: string
  api: string
}

interface ILogisticsFlowItem {
  flow_name: string
  order: OrderItem[]
  flowStatus: string
  fileGenerated: boolean
}

const logisticsFlowSlice: any = createSlice({
  name: 'logisticsFlow',
  initialState: logisticsProgressFlows as ILogisticsFlowItem[],
  reducers: {
    updateLogisticsFlowFileGenerated: (state, action: PayloadAction<{ flowName: string; fileGenerated: boolean }>) => {
      const { flowName } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item) {
        item.fileGenerated = true
      }
    },
    updateLogisticsOrderStatus: (state, action: PayloadAction<{ flowName: string; status: string }>) => {
      const { flowName, status } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item) {
        item.flowStatus = 'started'
        const orderItem = item.order.find((orderItem) => orderItem.status === status)
        if (orderItem) {
          orderItem.status = status
        }
      }
    },
    updateLogisticsFlowStatus: (state, action: PayloadAction<{ flowName: string; message: string }>) => {
      const { flowName, message } = action.payload
      const item = state.find((item) => item.flow_name === flowName)
      if (item && message === 'file generated successfully') {
        item.flowStatus = 'completed'
      }
    },
    // eslint-disable-next-line no-unused-vars
    setLogisticsFlow: (state, action: PayloadAction<ILogisticsFlowItem[]>) => {
      return action.payload
    },
    // eslint-disable-next-line no-unused-vars
    resetLogisticsFlow: (state, action) => {
      return action.payload
    },
  },
})

export const {
  updateLogisticsOrderStatus,
  updateLogisticsFlowFileGenerated,
  updateLogisticsFlowStatus,
  setLogisticsFlow,
  resetLogisticsFlow,
} = logisticsFlowSlice.actions

export default logisticsFlowSlice.reducer
