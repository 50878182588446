import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'

export const itemStatusFinder = (status: string) => {
  switch (status) {
    case 'success':
      return <CheckOutlined style={{ fontSize: '10px', color: 'green' }} />
    case 'fail':
      return <CloseOutlined style={{ fontSize: '10px', color: 'red' }} />
    case 'info':
      return <InfoCircleOutlined style={{ fontSize: '10px', color: 'rgb(33, 173, 232)' }} />
    case 'warning':
      return <InfoCircleOutlined style={{ fontSize: '10px', color: 'orange' }} />
    default:
      return <CheckOutlined />
  }
}
