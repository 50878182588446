import React from 'react'
import { Instructions, ContentContainer, List, ListItem } from 'styles/pages/sellerTesting'
import { Instruction, InstructionsWrapper } from 'styles/pages/termsConditions'

const TermsConditionList = () => {
  return (
    <>
      {' '}
      <InstructionsWrapper>
        <ContentContainer>
          <Instructions>
            <List>
              <ListItem>
                <Instruction>
                  {' '}
                  Acceptance of Terms:
                  <span>
                    By accessing and using Pramaan (the &quot;Service&quot;), you agree to be bound by these Terms and
                    Conditions. If you do not agree with any part of these terms, you must not use the Service.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Description of Service:
                  <span>
                    Pramaan is a digital authentication service provided by the Open Network for Digital Commerce
                    (ONDC). It offers self-serve utilities to assist entities wishing to participate in the ONDC
                    Network, allowing them to test integration in either a staging or pre-production environment.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Use of Service:
                  <span>
                    You may use the Service solely for lawful purposes and in accordance with these Terms. The Service
                    is available for testing purposes only. Engaging in testing activities does not confer the status of
                    a network participant in the ONDC Network.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Changes to Terms:
                  <span>
                    ONDC reserves the right, at its sole discretion, to modify or replace these Terms at any time
                    without any prior notification.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Termination:
                  <span>
                    ONDC may terminate or suspend access to the Service immediately, without prior notice or liability,
                    for any reason whatsoever, including without limitation if you breach the Terms.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Disclaimer of Warranties:
                  <span>
                    The Service is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. ONDC makes no
                    representations or warranties of any kind, express or implied, regarding the operation of the
                    Service, or the information, content, or materials included therein.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Limitation of Liability:
                  <span>
                    In no event shall ONDC be liable for any direct, indirect, incidental, special, consequential, or
                    exemplary damages resulting from your use or inability to use the Service.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Governing Law and Dispute Resolution:
                  <span>
                    These Terms shall be governed and construed in accordance with the laws of India, without regard to
                    its conflict of law provisions. The courts at New Delhi shall have exclusive jurisdiction.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Severability:
                  <span>
                    If any of the provisions of these Terms are held by a court or other tribunal of competent
                    jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the
                    minimum extent necessary and replaced with a valid provision that best embodies the intent of these
                    Terms, so that these Terms shall remain in full force and effect.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Personal Data:
                  <span>
                    Pramaan does not require personal data. If any personal data is shared while using the Service, ONDC
                    shall not be held accountable for any misuse of the information.
                  </span>
                </Instruction>
              </ListItem>
              <ListItem>
                <Instruction>
                  {' '}
                  Privacy Policy:
                  <span>
                    By accessing and using the Service, you agree to be bound by the Privacy Policy which is available
                    at
                    <span>
                      https://ondc.org/privacy-policy/#:~:text=Personal%20information%20will%20only%20be,parties%20for%20their%20
                      marketing%20purposes
                    </span>
                  </span>
                </Instruction>
              </ListItem>
            </List>
          </Instructions>
        </ContentContainer>
      </InstructionsWrapper>
    </>
  )
}

export default TermsConditionList
