import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import usePost from 'hooks/usePost'
import { SellerSelectModalProps } from 'interface'
import APIS from 'constants/api'
import { LoaderContext } from 'context/loader'
import Loader from 'components/Loader'
import {
  ButtonWrapper,
  Container,
  Heading,
  ModalBody,
  Text,
  TextContainer,
  TextWrapper,
} from 'styles/components/SelectModal'

const SellerSelectModal = ({ isModalOpen, setIsModalOpen, heading, setTimer }: SellerSelectModalProps) => {
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()

  const [itemList, setItemList] = useState<any>([])
  const [events, setEvents] = useState<any>(null)
  const sellerOnNetworkDataState = useSelector((state: any) => state?.sellerOnNetworkData)

  useEffect(() => {
    if (isModalOpen) {
      const test_id = sellerOnNetworkDataState?.testId
      const parsedData = sellerOnNetworkDataState?.testData
      const url = `${process.env.REACT_APP_BASE_URL}/ssebuyer/${test_id}?bap_id=${parsedData.logistics_bap_id}`

      const eventSource: EventSource = new EventSource(url)
      setEvents(eventSource)
      eventSource.addEventListener('search_call', (event: any) => {
        const data = JSON.parse(event.data)
        setItemList((prev: any) => [...prev, data])
        setTimer(false)
      })

      eventSource.addEventListener('stop_search', (event: any) => {
        const data = JSON.parse(event.data)
        setTimer(false)
        setIsModalOpen(false)
        eventSource.close()
        return data
      })

      return () => {
        events?.close()
      }
    } else {
      setItemList([])
    }
  }, [isModalOpen])

  const callSelect = async (item: any) => {
    try {
      const parsedData = sellerOnNetworkDataState?.testData
      setLoader(true)
      const test_id = sellerOnNetworkDataState?.testId
      const flow_id = sellerOnNetworkDataState?.flowId
      const payload = {
        flow_id: flow_id,
        transactionId: item.transactionId,
        testId: test_id,
        bapId: parsedData.logistics_bap_id,
        np_type: 'logistics',
      }

      await mutateAsync({
        url: APIS.SELECT,
        payload: payload,
        mockServer: true,
      })
      setIsModalOpen(false)
      setLoader(false)
      events.close()
      setEvents(null)

      setIsModalOpen(false)
    } catch (error) {
      setLoader(false)
    }
  }

  return (
    <>
      {isModalOpen && itemList.length === 0 ? (
        <Loader isModalOpen={true} text={'Waiting for 3 minutes for search call from Logistics Buyer'} />
      ) : (
        <Modal open={isModalOpen} onCancel={handleCancel} centered footer={null} width={'555px'} maskClosable={false}>
          <ModalBody>
            <Heading>{heading}</Heading>
            <Container>
              {itemList?.map((item: any, index: number) => {
                return (
                  <TextWrapper key={index}>
                    <TextContainer>
                      <Text>Category : {item?.category}</Text>
                      <Text>Bap Id : {item.bapId}</Text>
                      <Text>Action : {item.action}</Text>
                      <Text>Transaction Id : {item.transactionId}</Text>
                      <Text> TimeStamp : {item.timestamp}</Text>
                    </TextContainer>
                    <ButtonWrapper>
                      <button onClick={() => callSelect(item)}>Select</button>
                    </ButtonWrapper>
                  </TextWrapper>
                )
              })}
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default SellerSelectModal
