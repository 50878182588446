import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetsellerFlow } from 'store/sellerFlowSlice'
import { resetSellerOffNetworkData } from 'store/sellerDataSlice'

import Navbar from 'components/Navbar'
import CustomButton from 'components/Button'
import Footer from 'components/Footer'
import Stepper from 'components/Stepper'
import Watermark from 'assets/svg/watermark'

import {
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightWrapper,
  StepsWrapper,
  Wrapper,
  InfoWrapper,
  RightHeading,
  Container,
  FeedbackWrapper,
} from 'styles/pages/sellerTesting'
import Track from 'components/Track'
import { ContentWrapper, TrackWrapper, RightSection, ButtonWrapper } from 'styles/pages/orderTracking'

import { ConfigProvider, Steps } from 'antd'

const OrderTracking = () => {
  const [next, setNext] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSellerOffNetworkData([]))
    dispatch(resetsellerFlow([]))
    window.localStorage.clear()
    setNext(true)
  }, [])

  return (
    <>
      <Wrapper>
        <Navbar />
        <Container>
          <LeftWrapper>
            <NameWrapper>
              <Name>Pramaan</Name>
            </NameWrapper>
            <LeftContainer>
              <StepsWrapper>
                <Stepper current={3} next={next} navi="/" />
              </StepsWrapper>
              <Watermark />
              <FeedbackWrapper>
                <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
              </FeedbackWrapper>
            </LeftContainer>
          </LeftWrapper>
          <RightWrapper>
            <HeadingWrapper>
              <Heading>Order Tracking</Heading>
            </HeadingWrapper>
            <RightContainer>
              <RightSection>
                <TrackWrapper>
                  <Track />
                </TrackWrapper>
                <ContentWrapper></ContentWrapper>

                <InfoWrapper>
                  <RightHeading>Updates</RightHeading>
                </InfoWrapper>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: ' #606161',
                      borderRadius: 2,
                      colorBgContainer: '#f6ffed',
                    },
                  }}
                >
                  <Steps
                    progressDot
                    current={1}
                    direction="vertical"
                    items={[
                      {
                        title: 'Ordered',
                        description: 'This is a description. This is a description.',
                      },
                      {
                        title: 'In Transit',
                        description: 'This is a description. This is a description.',
                      },
                      {
                        title: 'Out Of Delivery',
                        description: 'This is a description.',
                      },
                      {
                        title: 'Delivered',
                        description: 'This is a description.',
                      },
                    ]}
                  />
                </ConfigProvider>
                <ButtonWrapper>
                  <CustomButton label={'Go To Home page'} variant="contained" onClick={() => navigate('/')} />
                </ButtonWrapper>
              </RightSection>
            </RightContainer>
          </RightWrapper>
        </Container>

        <Footer />
      </Wrapper>
    </>
  )
}

export default OrderTracking
