import { IStyledButton } from 'interface'
import styled from 'styled-components'

const getButtonStyles = ({ variant }: IStyledButton) => {
  switch (variant) {
    case 'contained':
      return `
       border-radius: 8px;
      background: #21ADE8;
      color:#FFFFFF; 
      border: none;
      cursor: pointer;  
      `
    case 'gradient':
      return `
     border-radius: 8px;
      background: linear-gradient(95deg, #196AAB 0.5%, #01ADEE 100%);
      color:#FFFFFF; 
      border: none;
      cursor: pointer;  
      `

    case 'disabled':
      return `
          background:transparent;
          color:gray;
          border: 1px solid;
          cursor: not-allowed;
     `
    case 'outline':
      return `
      background:transparent;
      color:#21ADE8; 
      border: 1px solid #21ADE8 ;
      cursor: pointer; 
`
    case 'text':
      return `
         background:transparent; 
         color:#21ADE8; 
         border: none; 
         cursor: pointer;  
`
    default:
      return `
      background:#21ADE8;
      color:#FFFFFF; 
      border: none;
      cursor: pointer;  
      `
  }
}

export const StyledButton = styled.button<IStyledButton>`
  ${({ variant }) => getButtonStyles({ variant })};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
`
