enum APIS {
  CREATE_SESSION = '/v1/create_session',
  POST_TEST = '/v1/test',
  POST_FLOW = '/v1/test/flow',
  START_TEST = '/v1/start',
  GET_TEST = '/v1/test',
  GET_ALL_FLOW = '/v1/test/flow',
  GET_XML = '/XML',
  POST_TEST_FLOW = '/test-flows',
  POST_START_TEST = '/v1/test/start',
  POST_START_TEST_BUYER = '/v1/test/buyer/start',
  GET_REPORT = '/result',
  SELECT = '/bap',
}
export default APIS
