import Navbar from 'components/Navbar'
import { Container, Link, Number, Text, Wrapper } from 'styles/components/404'

const PageNotFound = () => {
  return (
    <>
      <Wrapper>
        <Navbar />
        <Container>
          <Number>404</Number>
          <Text>Page not found</Text>
          <Link href="/">Go to Homepage</Link>
        </Container>
      </Wrapper>
    </>
  )
}

export default PageNotFound
