import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EventData {
  testId: string
  status: string
  message: string
  sub_group: string
  flow_name: string
}

interface EventsState {
  [flow_name: string]: EventData[]
}

const initialState: EventsState = {}

const logsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addEvent: (state, action: PayloadAction<EventData>) => {
      const { flow_name } = action.payload
      if (!state[flow_name]) {
        state[flow_name] = []
      }

      state[flow_name].push(action.payload)
    },
    clearEventsByFlow: (state, action: PayloadAction<string>) => {
      const flowName = action.payload
      if (state[flowName]) {
        state[flowName] = []
      }
    },
    clearAllEvents: (state) => {
      for (const flowName in state) {
        state[flowName] = []
      }
    },
  },
})
export const selectEventsByFlow = (state: EventsState, flowName: string) => state[flowName] || []

export const { addEvent, clearEventsByFlow, clearAllEvents } = logsSlice.actions
export default logsSlice.reducer
