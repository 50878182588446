import React from 'react'
import { IButtonProps } from 'interface'
import { StyledButton } from 'styles/components/Button'
const CustomButton: React.FC<IButtonProps> = ({ label, variant, type, icon, disabled, ...rest }: IButtonProps) => (
  <StyledButton variant={variant} type={type} disabled={disabled} {...rest}>
    {label}
    {icon}
  </StyledButton>
)
export default CustomButton
