import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #0000005c;
  justify-content: center;
  align-items: center;
  z-index: 10000000059;
  .loader-icon {
    position: absolute;
  }
  img {
    position: absolute;
    width: 100%;
    max-width: 100px;
    @media screen and (max-width: 650px) {
      max-width: 80px;
    }
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 16.667vw;
    height: 16.667vw;
  }
  .loader {
    margin: 60px auto;
    position: relative;

    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
