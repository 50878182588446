import Watermark from 'assets/svg/watermark'
import Stepper from 'components/Stepper'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import {
  Container,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  StepsWrapper,
  Wrapper,
} from 'styles/pages/feedback'
import { useEffect, useState } from 'react'
import { RightContainer, RightSection, RightWrapper } from 'styles/pages/sellerTesting'
import TermsConditionList from 'components/Terms'

const TermsConditions = () => {
  const [next, setNext] = useState(false)
  useEffect(() => {
    setNext(true)
  }, [])

  return (
    <Wrapper>
      <Navbar />

      <Container>
        <LeftWrapper>
          <NameWrapper>
            <Name>Pramaan</Name>
          </NameWrapper>
          <LeftContainer>
            <StepsWrapper>
              <Stepper current={3} next={next} navi="/" />
            </StepsWrapper>
            <Watermark />
          </LeftContainer>
        </LeftWrapper>
        <RightWrapper>
          <HeadingWrapper>
            <Heading>Terms and Conditions </Heading>
          </HeadingWrapper>
          <RightContainer>
            <RightSection>
              <TermsConditionList />
            </RightSection>
          </RightContainer>
        </RightWrapper>
      </Container>
      <Footer />
    </Wrapper>
  )
}

export default TermsConditions
